import {Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions} from "chart.js";
import {useMemo} from "react";
import {Pie} from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

interface PoolsProps {
	platform_retention: number;
	platform_retained_profit: number;
	influencer_share_pool: number;
	partner_share_pool: number;
	distribution_pool: number;
}

const options: ChartOptions<"pie"> = {
	plugins: {
		legend: {
			display: true,
		},
		tooltip: {
			callbacks: {
				label(context) {
					return new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(context.parsed / 100);
				},
			},
		},
	},
};

const Pools = (props: PoolsProps) => {
	const dataChart = useMemo(() => {
		return {
			labels: ["Utilidades retenidas de la plataforma", "Pool de influencer", "Pool de partner"],
			datasets: [
				{
					label: "Distribución de ganancias",
					data: [props.platform_retained_profit, props.influencer_share_pool, props.partner_share_pool],
					backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
					hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
				},
			],
		};
	}, [props]);

	return (
		<div className="flex justify-center gap-4">
			<Pie data={dataChart} options={options} />
		</div>
	);
};

export default Pools;
