import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend, ChartData} from "chart.js";
import {DateRange} from "react-day-picker";
import {useMemo, useRef, useState, useEffect} from "react";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {format} from "date-fns";
import {Spinner} from "../../../../components/primitives/icons";
import {Bar} from "react-chartjs-2";
import {oneMonth} from "../../../../../utils/barGraphTransformData";
import {enUS} from "date-fns/locale";
import {GetActiveUsersResponse} from "../../../../data-access/dashboard/types";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

interface LikesAndFavoritesProps {
	dates: DateRange;
	statistics?: GetActiveUsersResponse | undefined;
	isLoading?: boolean;
}

export function ActiveInactiveChartDetail({dates, statistics, isLoading}: LikesAndFavoritesProps) {
	const chartRef = useRef<ChartJSOrUndefined<"bar", {date: string; created?: number; disabled?: number}[]>>(null);

	const [showByMonths, setShowByMonths] = useState(false);

	useEffect(() => {
		const duration = dates ? dates?.to!.getTime() - dates?.from!.getTime() : 0;
		setShowByMonths(duration > oneMonth);
	}, [dates]);

	const data: ChartData<"bar", {date: string; created?: number; disabled?: number}[]> = useMemo(() => {
		if (!statistics?.daily_data) {
			return {
				datasets: [
					{
						label: "Registrados",
						data: [
							{
								date: format(new Date(), "yyyy-MM-dd"),
								created: 0,
							},
						],
						backgroundColor: "#088395",
						borderRadius: 4,
						parsing: {
							xAxisKey: "date",
							yAxisKey: "created",
						},
					},
				],
			};
		}

		return {
			datasets: [
				{
					label: "Registrados",
					data: statistics?.daily_data?.map(item => ({
						date: item.date,
						created: item.created,
					})),
					backgroundColor: "#088395",
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "created",
					},
				},
				{
					label: "Eliminados",
					data: statistics?.daily_data?.map(item => ({
						date: item.date,
						disabled: item.disabled,
					})),
					backgroundColor: "#B30000",
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "disabled",
					},
				},
			],
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statistics?.daily_data, showByMonths]);

	return (
		<div className="flex w-full py-6">
			<div className="flex w-full flex-col rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Usuarios activos</h3>
				</div>
				{isLoading ? (
					<div className="flex h-[350px] items-center justify-center">
						<Spinner />
					</div>
				) : (
					<div className="flex w-full p-6 pt-0">
						<Bar
							ref={chartRef}
							height={350}
							options={{
								plugins: {
									legend: {
										position: "top",
									},
									tooltip: {
										callbacks: {
											title(tooltipItems) {
												const date = new Date(tooltipItems[0].label);
												if (showByMonths) {
													return format(date, "MMM - yyyy", {locale: enUS}).toLowerCase();
												} else {
													return format(date, "d MMMM", {locale: enUS}).toLowerCase();
												}
											},
										},
									},
								},
								responsive: true,
								maintainAspectRatio: false,
								interaction: {
									mode: "index" as const,
									intersect: false,
								},
								scales: {
									x: {
										ticks: {
											callback(tickValue) {
												const date = new Date(this.getLabelForValue(tickValue as any));

												const formattedDate = showByMonths
													? format(date, "MMM - yyyy", {locale: enUS}).toLowerCase()
													: format(date, "MMM d", {locale: enUS}).toLowerCase();

												return formattedDate;
											},
										},
										grid: {
											color: "transparent",
										},
									},
									y: {
										ticks: {
											precision: 0,
										},
									},
								},
							}}
							data={data}
							id="likes"
						/>
					</div>
				)}
			</div>
		</div>
	);
}
