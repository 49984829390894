import {selectedInfluencerSeries} from "../../../../../_store/features/dashboard/dashboard-slice";
import {useAppSelector} from "../../../../../_store/hooks";
import {Report} from "../../../../primitives/icons";
import NavLink from "../../subcomponents/NavLink";

export function InfluencerNavigationSection() {
	const series = useAppSelector(selectedInfluencerSeries);

	return (
		<div className="overflow-y-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
			<NavLink to="/influencer/reports" label="Reportes" icon={<Report />} />
		</div>
	);
}
