import {ChartOptions} from "chart.js";
import format from "date-fns/format";
import enUS from "date-fns/locale/en-US";

export interface UserDataset {
	label: string;
	data: {date: string; count: number; total: number}[];
	backgroundColor: string;
	borderRadius: number;
	borderColor?: string;
	tension?: number;
	parsing: {xAxisKey: string; yAxisKey: string};
	type?: any;
	order?: number;
}

export const subscribedData: UserDataset = {
	label: "Subscriptores",
	data: [],
	backgroundColor: "rgb(5, 158, 0, .6)",
	borderRadius: 10,
	parsing: {xAxisKey: "date", yAxisKey: "count"},
};

export const registeredData: UserDataset = {
	label: "Registrados Free",
	data: [],
	backgroundColor: "rgba(48, 168, 72, 0.4)",
	borderRadius: 10,
	parsing: {xAxisKey: "date", yAxisKey: "count"},
};

export const missingData: UserDataset = {
	label: "No Completaron",
	data: [],
	backgroundColor: "rgba(0, 0, 0, 0.1)",
	borderRadius: 10,
	parsing: {xAxisKey: "date", yAxisKey: "count"},
};

export const convertionPorcentData: UserDataset = {
	label: "% Conversión",
	data: [],
	backgroundColor: "rgb(15, 45, 214)",
	borderRadius: 10,
	parsing: {xAxisKey: "date", yAxisKey: "count"},
	type: "line",
	borderColor: "rgb(15, 45, 214, 0.6)",
	tension: 0.4,
	order: 1,
};

export const missingPorcentData: UserDataset = {
	label: "% Perdidos",
	data: [],
	backgroundColor: "rgba(196, 2, 2, 0.7)",
	borderRadius: 10,
	parsing: {xAxisKey: "date", yAxisKey: "count"},
	type: "line",
	borderColor: "rgb(196, 2, 2, 0.4)",
	tension: 0.4,
	order: 2,
};

export const chartOptions: ChartOptions<"bar"> = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		tooltip: {
			callbacks: {
				title(tooltipItems) {
					return tooltipItems[0].label;
				},
				label(context) {
					let label = context.dataset.label || "";
					if (context.parsed.y !== null && !label.includes("%")) {
						label +=
							": " +
							context.parsed.y.toFixed(0) +
							" de " +
							(context.dataset.data[context.dataIndex] as any).total +
							" (" +
							((context.parsed.y / (context.dataset.data[context.dataIndex] as any).total) * 100).toFixed(2) +
							"%)";
					} else {
						label += ": " + ((context.parsed.y / (context.dataset.data[context.dataIndex] as any).total) * 100).toFixed(2) + "%";
					}
					return label;
				},
			},
		},
	},
	scales: {
		x: {
			ticks: {
				callback(tickValue) {
					return format(new Date(this.getLabelForValue(tickValue as any)), "MMM d", {locale: enUS}).toLowerCase();
				},
			},
			grid: {
				color: "transparent",
			},
		},
	},
};
