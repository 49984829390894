import {ChartData, ChartOptions} from "chart.js";
import {useMemo, useRef} from "react";
import {DateRange} from "react-day-picker";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {Line} from "react-chartjs-2";
import {LineElement, CategoryScale, Chart as ChartJS, Tooltip as ChartJSTooltip, LinearScale} from "chart.js";
import {format, formatISO9075} from "date-fns";
import {es} from "date-fns/locale";
import {useGetInfluencerPerformanceQuery} from "../../../../../_store/features/influencer/api";
import {Spinner} from "../../../../../components/primitives/icons";

ChartJS.register(CategoryScale, LinearScale, LineElement, ChartJSTooltip);

interface InfluencerBarChartProps {
	dates: DateRange;
}

interface Point {
	date: string;
	value: number;
}

const ReferralPerformanceLineChart = ({dates}: InfluencerBarChartProps) => {
	const chartRef = useRef<ChartJSOrUndefined<"line", Point[]>>(null);

	const {data: influencerPerformance, isLoading} = useGetInfluencerPerformanceQuery({
		final_date: formatISO9075(dates.to!),
		initial_date: formatISO9075(dates.from!),
	});

	const data: ChartData<"line", Point[]> | null = useMemo(() => {
		if (!dates?.from || !dates?.to) return {datasets: []};

		const rangeLenght = Math.ceil((new Date(dates.to).getTime() - new Date(dates.from).getTime()) / 1000 / 86400);

		const selectedData: Point[] = Array.from({length: rangeLenght}, (_, i) => {
			var date = new Date(dates.from!);
			date.setDate(date.getDate() + i);

			return {date: formatISO9075(date, {representation: "date"}), value: 0, total: 0};
		});

		const performanceData = Object.entries(influencerPerformance?.series_data ?? {});

		for (const [date, value] of performanceData) {
			const time = Object.values(value).reduce((acc, curr) => {
				if (curr.serie_playing_time) acc += curr.serie_playing_time;
				return acc;
			}, 0);
			const index = selectedData.findIndex(data => data.date === date);
			if (index !== -1) {
				selectedData[index].value = time;
			}
		}

		return {
			labels: selectedData.map(data => data.date),
			datasets: [
				{
					label: "Tiempo de reproducción",
					data: selectedData,
					fill: false,
					backgroundColor: "#bb4422",
					borderColor: "#bb4422",
					borderWidth: 1,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "value",
					},
				},
			],
		};
	}, [dates.from, dates.to, influencerPerformance]);

	if (!influencerPerformance || influencerPerformance?.total_hours === 0 || !influencerPerformance?.total_series_hours) {
		return null;
	}

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 pt-6 md:grid-cols-2 lg:grid-cols-7">
			<div className="relative col-span-7 rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Tiempo de reproducción</h3>
				</div>
				{isLoading ? (
					<div className="flex h-[350px] items-center justify-center">
						<Spinner />
					</div>
				) : (
					<div className="relative p-6 pt-0">
						<Line
							height={350}
							ref={chartRef}
							data={data}
							options={{
								...options,
								scales: {
									x: {
										ticks: {
											callback(tickValue) {
												let value = this.getLabelForValue(tickValue as any);
												const date: Date = new Date(`${value}T00:00:00`);
												let formattedDate: string;

												formattedDate = format(date, "MMM d", {locale: es}).toLowerCase();

												return formattedDate;
											},
										},
										grid: {
											color: "transparent",
										},
										stacked: true,
									},
									y: {
										ticks: {
											precision: 1,
										},
										stacked: true,
									},
								},
							}}
						/>
						<div className="absolute -top-10 right-10 text-xs">
							<div>
								<div>
									<b>Series relacionadas:</b> {influencerPerformance?.total_series_hours ?? 0} horas
								</div>
							</div>
							<div>
								<div>
									<b>Plataforma:</b> {influencerPerformance?.total_hours ?? 0} horas
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default ReferralPerformanceLineChart;

const options: ChartOptions<"line"> = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		tooltip: {
			callbacks: {
				title(tooltipItems) {
					return "Tiempo de reproducción - " + tooltipItems[0].label;
				},
				label(context) {
					const item = context.dataset.data[context.dataIndex] as unknown as Point;

					if (!item) return "";

					return "Tiempo de reproducción: " + Math.round(item.value) + " horas";
				},
			},
		},
		legend: {
			display: false,
		},
	},
	scales: {
		x: {
			ticks: {
				callback(tickValue) {
					return format(new Date(this.getLabelForValue(tickValue as any)), "MMM d", {locale: es}).toLowerCase();
				},
			},
			grid: {
				color: "transparent",
			},
		},
	},
};
