import {Report} from "../../../../_store/features/influencer/types";

interface DetailsProps {
	report: Report;
	isLoading: boolean;
}

const Details = ({report, isLoading}: DetailsProps) => {
	const subscriptions = [
		{type: "Mensual", total: report.subscriptions.monthly_subscriptions, amount: report.subscriptions.monthly_earn},
		{type: "Anual", total: report.subscriptions.annual_subscriptions, amount: report.subscriptions.annual_earn},
	];

	return (
		<div className="grid grid-cols-1 gap-4">
			<div className="relative flex flex-col justify-between overflow-hidden rounded-lg border bg-card pb-4 text-card-foreground shadow-sm">
				<div className="flex items-center justify-between bg-neutral-100 p-4">
					<h1 className="truncate text-base font-semibold tracking-tight">Ingresos por Visualizaciones </h1>
				</div>

				<div className="px-4 sm:px-6 lg:px-8 ">
					<table className="min-w-full">
						<colgroup>
							<col className="w-full sm:w-1/4" />
							<col className="sm:w-[12.5%]" />
							<col className="sm:w-[12.5%]" />
							<col className="sm:w-[12.5%]" />
							<col className="sm:w-[12.5%]" />
						</colgroup>
						<thead className="border-b border-gray-300 text-gray-900">
							<tr>
								<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
									Serie
								</th>
								<th scope="col" className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell">
									Visualizaciones horas
								</th>
								<th scope="col" className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell">
									Performance
								</th>
								<th scope="col" className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell">
									Porcentaje
								</th>
								<th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
									Ingresos
								</th>
							</tr>
						</thead>
						<tbody>
							{report.series.map((serie, index) => (
								<tr key={index} className="border-b border-gray-200">
									<td className="max-w-0 py-2 pl-4 pr-3 text-sm sm:pl-0">
										<div className="font-medium text-gray-900">{serie.title}</div>
									</td>
									<td className="hidden px-3 py-2 text-right text-sm text-gray-500 sm:table-cell">
										{Math.round(serie.total_seconds / 3600)}
									</td>
									<td className="hidden px-3 py-2 text-right text-sm text-gray-500 sm:table-cell">
										{Math.round(serie.performance * 100)} %
									</td>
									<td className="hidden px-3 py-2 text-right text-sm text-gray-500 sm:table-cell">
										{serie.influencers.profit_percentage}%
									</td>
									<td className="py-2 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">
										{Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(serie.influencers.earn / 100)}
									</td>
								</tr>
							))}
						</tbody>
						<tfoot>
							<tr>
								<th
									scope="col"
									colSpan={4}
									className="hidden pl-4 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0"
								>
									Total
								</th>
								<th scope="row" className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden">
									Total
								</th>
								<td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">
									{Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(report.influencer_earn_total / 100)}
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
			<div className="relative flex flex-col justify-between overflow-hidden rounded-lg border bg-card pb-4 text-card-foreground shadow-sm">
				<div className="flex items-center justify-between bg-neutral-100 p-4">
					<h1 className="truncate text-base font-semibold tracking-tight">Ingresos por suscripciones </h1>
				</div>

				<div className="px-4 sm:px-6 lg:px-8 ">
					<table className="min-w-full">
						<colgroup>
							<col className="w-full sm:w-2/3" />
							<col className="sm:w-1/6" />
							<col className="sm:w-1/6" />
						</colgroup>
						<thead className="border-b border-gray-300 text-gray-900">
							<tr>
								<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
									Tipo
								</th>
								<th scope="col" className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell">
									Cantidad
								</th>
								<th scope="col" className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell">
									Ganancias
								</th>
							</tr>
						</thead>
						<tbody>
							{subscriptions.map((detail, index) => (
								<tr key={index} className="border-b border-gray-200">
									<td className="max-w-0 py-5 pl-4 pr-3 text-sm sm:pl-0">
										<div className="font-medium text-gray-900">{detail.type}</div>
									</td>
									<td className="hidden px-3 py-5 text-right text-sm text-gray-500 sm:table-cell">{detail.total}</td>
									<td className="hidden px-3 py-5 text-right text-sm text-gray-500 sm:table-cell">
										{Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(detail.amount / 100)}
									</td>
								</tr>
							))}
						</tbody>
						<tfoot>
							<tr>
								<th
									scope="col"
									className="hidden pl-4 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0"
								>
									Total
								</th>
								<th scope="row" className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden">
									Total
								</th>
								<td className="items-center px-3 pt-4 text-right text-sm font-semibold text-gray-900 ">
									{report.subscriptions.subscriptions}
								</td>
								<td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 ">
									{Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(
										(report.subscriptions.annual_earn + report.subscriptions.monthly_earn) / 100,
									)}
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</div>
	);
};

export default Details;
