import {formatInTimeZone} from "date-fns-tz";
import {InfluencerCommission, Performance as PerformanceType, PlatformReport, ReportResponse} from "../../../../_store/features/reports/types";
import {InformationCircle} from "../../../../components/primitives/icons/InformationCircle";
import {es} from "date-fns/locale";
import {Card} from "../../../../components/blocks/Stats/Card";
import Pools from "./charts/pools";
import CostTable from "./cost-table";
import React, {useEffect, useState} from "react";
import {Popover, PopoverContent, PopoverTrigger} from "../../../../components/primitives/Popover/popover";
import {Button} from "../../../../components/primitives/Button";
import {Slider} from "../../../../components/primitives/Slider";
import {Eye, QuestionMarkCircle} from "../../../../components/primitives/icons";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "../../../../components/primitives/ToolTip";
import {useDeleteReportMutation, usePostCreateReportMutation} from "../../../../_store/features/reports/api";
import LoadingDots from "../../../../components/blocks/LoadingDots";
import {cn} from "../../../../../utils/classNames";
import {Modal} from "../../../../components/primitives/Modal";
import {Influencers} from "../../../../_store/features/influencer/types";
import { Switch } from "../../../../components/primitives/Switch";
import { Label } from "../../../../components/primitives/Label";
import toast from "react-hot-toast";

interface ReportProps {
	data: ReportResponse | undefined;
	isLoading: boolean;
	isError: boolean;
	initialDate: string;
	finalDate: string;
}

function capitalize(s: string) {
	if (typeof s !== "string") return "";
	return s.charAt(0).toUpperCase() + s.slice(1);
}

const Report = ({data, isError, isLoading, finalDate, initialDate}: ReportProps) => {
	const [preview, setPreview] = useState<PlatformReport | null>(null)

	useEffect(() => {
		setPreview(null);
	}, [initialDate, finalDate])

	if (preview) {
		return <ReportBody finalDate={finalDate} initialDate={initialDate} onReportPreview={setPreview} report={preview} isPreview />
	}

	if (!data?.report || isError) return <NoReportAvailable finalDate={finalDate} initialDate={initialDate} onReportPreview={setPreview}/>;

	if (isLoading) {
		return (
			<div className="mx-auto my-10 flex h-full max-w-lg flex-col items-center justify-center text-center">
				<p className="mb-4 rounded-full p-3 text-sm font-medium text-secondary">
					<InformationCircle className="h-10 w-10 text-black" />
				</p>
				<h1 className="scroll-m-20 text-xl font-extrabold tracking-tight lg:text-2xl">Cargando...</h1>
			</div>
		);
	}

	return <ReportBody finalDate={finalDate} initialDate={initialDate} onReportPreview={setPreview} report={data.report} />
};

interface ReportBodyProps extends ComponentDateProps{
	report: PlatformReport;
	isPreview?: boolean;
}

const ReportBody = ({report, finalDate, initialDate, onReportPreview, isPreview}: ReportBodyProps) => {
	const {
		report_initial_date,
		distribution_pool,
		influencer_share_pool,
		operational_costs,
		partner_share_pool,
		performance,
		platform_net_profit,
		platform_retained_profit,
		platform_retention,
		subscriptions_revenue,
		pool_influencer_share,
		costs_list,
		influencer_commission,
	} = report;

	return (
		<section className="flex flex-col space-y-4 p-6 py-4">
			<div className="flex items-center justify-between">
				<h1 className="text-sm font-light tracking-tight">
					Reporte {capitalize(formatInTimeZone(new Date(report_initial_date), "UTC", "MMMM yyyy", {locale: es}))}
				</h1>
				<div className="flex items-center gap-4">
				 	<SetupReportPopover finalDate={finalDate} initialDate={initialDate} onReportPreview={onReportPreview} force isPreview={isPreview} />
					{!isPreview && <RemoveButton finalDate={finalDate} initialDate={initialDate} />}
				</div>
			</div>
			<div className="flex flex-col gap-4">
				<div className="col-span-2 grid grid-cols-1 gap-2 py-10 md:grid-cols-5">
					<Card title="Ingresos brutos" amount={subscriptions_revenue.gross_revenue / 100} />
					<Card title="Fee tiendas" amount={subscriptions_revenue.store_fees_total / 100} />
					<Card title="Ingresos d/ de fees" amount={subscriptions_revenue.net_revenue / 100} />
					<Card title="Comisión influencers" amount={subscriptions_revenue.influencer_commission_total / 100} />
					<Card title="Gastos operativos" amount={operational_costs / 100} />
					<Card title="Ingresos netos" amount={platform_net_profit / 100} />
					<Card title={`Utilidades retenidas (${Math.round(platform_retention * 100)}%)`} amount={platform_retained_profit / 100} />
					<Card title="Pool distribución" amount={distribution_pool / 100} />
					<Card title={`Pool Asociados (${Math.round(pool_influencer_share * 100)}%)`} amount={influencer_share_pool / 100} />
					<Card title="Pool Partner" amount={partner_share_pool / 100} />
				</div>

				<div className="grid md:grid-cols-3">
					<InfluencerRanking data={influencer_commission} />
					<Pools
						platform_retention={platform_retention}
						distribution_pool={distribution_pool}
						influencer_share_pool={influencer_share_pool}
						partner_share_pool={partner_share_pool}
						platform_retained_profit={platform_retained_profit}
					/>
				</div>

				<Performance data={performance} />
				<CostTable list={costs_list} />
			</div>
		</section>
	);
}

interface RemoveButtonProps {
	initialDate: string;
	finalDate: string;
}

const RemoveButton = ({finalDate, initialDate}: RemoveButtonProps) => {
	const [deleteReportPromise, {isLoading}] = useDeleteReportMutation();

	const handleOnDelete = async () => {
		try {
			if (!window.confirm("¿Seguro que deseas borrar el reporte? Esta acción es irreversible y afectará a todos los reportes de asociados en este período.")) {
				return;
			}

			await deleteReportPromise({final_date: finalDate, initial_date: initialDate}).unwrap();
			toast.success("Reporte eliminado correctamente.")
		} catch (error) {
			toast.error("Ha ocurrido un error al eliminar el reporte.");
		}
	}

	return <Button variant="destructive" onClick={handleOnDelete}>{isLoading ? <LoadingDots color="white" /> : <span>Eliminar</span>}</Button>
}

const Performance = ({data}: {data: PerformanceType[]}) => {
	const [open, setOpen] = useState(false);
	const [influencers, setInfluencers] = useState<Influencers[]>([]);

	const handleOnClick = (influencers: Influencers[]) => {
		setOpen(true);
		setInfluencers(influencers);
	};

	return (
		<div className="md:col-span-2">
			<div className="inline-block min-w-full py-2 align-middle">
				<div className="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
					<table className="min-w-full divide-y divide-gray-300 text-left">
						<colgroup>
							<col className="w-full sm:w-4/12" />
							<col className="lg:w-4/12" />
							<col className="lg:w-4/12" />
							<col className="lg:w-1/12" />
						</colgroup>
						<thead className="border-b border-gray-300 text-gray-900">
							<tr>
								<th scope="col" className="py-2 pl-4 pr-8 font-semibold text-gray-900 sm:pl-6 lg:pl-8">
									Serie
								</th>
								<th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold text-gray-900 sm:pr-8 sm:text-left lg:pr-20">
									Tiempo visualización
								</th>
								<th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold text-gray-900 md:table-cell lg:pr-20">
									Pool de distribución
								</th>
								<th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold text-gray-900 sm:table-cell">
									Asociados
								</th>
							</tr>
						</thead>
						<tbody className="divide-y divide-gray-200 bg-white">
							{data.map((serie, index) => (
								<tr key={`inf-${index}`}>
									<td className="pl-4 pr-8 sm:pl-6 lg:pl-8">
										<div className="flex items-center gap-x-4">
											<div className="truncate text-sm/6 font-medium text-gray-900">{serie.title}</div>
										</div>
									</td>
									<td className="hidden py-4 pl-0 pr-8 text-sm/6 text-gray-900 md:table-cell lg:pr-20">
										{Math.round(serie.total_seconds / 3600)} hrs
									</td>
									<td className="hidden py-4 pl-0 pr-4 text-right text-sm/6 text-gray-900 sm:table-cell sm:pr-6 lg:pr-8">
										<div className="flex gap-x-3">
											{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(serie.share_pool / 100)}
										</div>
									</td>
									<td className="hidden py-4 pl-0 pr-4 sm:table-cell">
										<div className="flex items-center gap-x-3">
											<div className="font-mono text-sm/6 text-gray-900">{serie.influencers.length}</div>

											<Button variant="outline" onClick={() => handleOnClick(serie.influencers)}>
												<Eye className="size-3" />
											</Button>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<InfluencerResumeModal open={open} onClose={() => setOpen(false)} influencers={influencers} />
				</div>
			</div>
		</div>
	);
};

interface InfluencerResumeModalProps {
	open: boolean;
	onClose: () => void;
	influencers: Influencers[];
}

const InfluencerResumeModal = ({open, onClose, influencers}: InfluencerResumeModalProps) => {
	return (
		<Modal open={open} onDismiss={onClose} big>
			<table className="min-w-full divide-y divide-gray-300 text-left">
				<colgroup>
					<col className="w-full sm:w-4/12" />
					<col className="lg:w-4/12" />
					<col className="lg:w-4/12" />
					<col className="lg:w-1/12" />
				</colgroup>
				<thead className="border-b border-gray-300 text-white">
					<tr>
						<th scope="col" className="py-2 pl-4 pr-8 font-semibold text-white sm:pl-6 lg:pl-8">
							Nombre
						</th>
						<th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold text-white sm:pr-8 sm:text-left lg:pr-20">
							Pool de distribución
						</th>
						<th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold text-white md:table-cell lg:pr-20">
							Porcentaje
						</th>
						<th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold text-white sm:table-cell">
							Ganancias
						</th>
					</tr>
				</thead>
				<tbody className="divide-y divide-gray-200 ">
					{influencers.map((serie, index) => (
						<tr key={`inf-${index}`}>
							<td className="pl-4 pr-8 sm:pl-6 lg:pl-8">
								<div className="flex items-center gap-x-4">
									<div className="truncate text-sm/6 font-medium text-white">{serie.name}</div>
								</div>
							</td>
							<td className="hidden py-4 pl-0 pr-8 text-sm/6 text-white md:table-cell lg:pr-20">{serie.referral_code}</td>
							<td className="hidden py-4 pl-0 pr-4 text-right text-sm/6 text-white sm:table-cell sm:pr-6 lg:pr-8">
								<div className="flex gap-x-3">{serie.profit_percentage} %</div>
							</td>
							<td className="hidden py-4 pl-0 pr-4 sm:table-cell">
								<div className="flex items-center gap-x-3">
									<div className="font-mono text-sm/6 text-white">
										{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(serie.earn / 100)}
									</div>
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</Modal>
	);
};

const InfluencerRanking = ({data}: {data: InfluencerCommission[]}) => {
	return (
		<div className="md:col-span-2">
			<div className="inline-block min-w-full py-2 align-middle">
				<div className="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
					<table className="min-w-full divide-y divide-gray-300 text-left">
						<colgroup>
							<col className="w-full sm:w-4/12" />
							<col className="lg:w-1/12" />
							<col className="lg:w-4/12" />
							<col className="lg:w-1/12" />
						</colgroup>
						<thead className="border-b border-gray-300 text-gray-900">
							<tr>
								<th scope="col" className="py-2 pl-4 pr-8 font-semibold text-gray-900 sm:pl-6 lg:pl-8">
									Código referido
								</th>
								<th scope="col" className="py-2 pl-0 pr-4 text-right font-semibold text-gray-900 sm:pr-8 sm:text-left lg:pr-20">
									Suscripciones
								</th>
								<th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold text-gray-900 md:table-cell lg:pr-20">
									Ingresos brutos (plataforma)
								</th>
								<th scope="col" className="hidden py-2 pl-0 pr-8 font-semibold text-gray-900 sm:table-cell">
									Ganancias
								</th>
							</tr>
						</thead>
						<tbody className="divide-y divide-gray-200 bg-white">
							{data.map((influencer, index) => (
								<tr key={`inf-${index}`}>
									<td className="pl-4 pr-8 sm:pl-6 lg:pl-8">
										<div className="flex items-center gap-x-4">
											<div className="truncate text-sm/6 font-medium text-gray-900">{influencer.referral_code}</div>
										</div>
									</td>
									<td className="hidden py-4 pl-0 pr-8 text-sm/6 text-gray-900 md:table-cell lg:pr-20">
										{influencer.subscriptions}
									</td>
									<td className="hidden py-4 pl-0 pr-4 text-right text-sm/6 text-gray-900 sm:table-cell sm:pr-6 lg:pr-8">
										<div className="flex gap-x-3">
											{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(
												influencer.gross_revenue / 100,
											)}
										</div>
									</td>
									<td className="hidden py-4 pl-0 pr-4 sm:table-cell">
										<div className="flex gap-x-3">
											<div className="font-mono text-sm/6 text-gray-900">
												{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(
													influencer.influencer_commission_total / 100,
												)}
											</div>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

interface ComponentDateProps {
	initialDate: string;
	finalDate: string;
	onReportPreview: (report: PlatformReport | null) => void;
}

const NoReportAvailable = ({initialDate, finalDate, onReportPreview}: ComponentDateProps) => {
	return (
		<div className="mx-auto my-10 flex h-full max-w-lg flex-col items-center justify-center text-center">
			<p className="mb-4 rounded-full p-3 text-sm font-medium text-secondary">
				<InformationCircle className="h-10 w-10 text-black" />
			</p>
			<h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-4xl">Reporte no disponible aún.</h1>
			<p className="mb-4 leading-7 [&:not(:first-child)]:mt-6">No se ha generado un reporte aún</p>

			<SetupReportPopover finalDate={finalDate} initialDate={initialDate} onReportPreview={onReportPreview} isPreview/>
		</div>
	);
};

interface SetupReportPopoverProps extends ComponentDateProps {
	force?: boolean;
	isPreview?: boolean;
}

const SetupReportPopover = ({initialDate, finalDate, force = false, onReportPreview, isPreview = false}: SetupReportPopoverProps) => {
	const [open, setOpen] = React.useState(false);
	const [preview, setPreview] = React.useState(isPreview);
	const [platform_retention, setRetention] = React.useState(0.3);
	const [pool_influencer_share, setInfluencerPool] = React.useState(0.4);

	const [createReport, {isLoading}] = usePostCreateReportMutation();

	const label = force ? "Volver a generar" : "Generar reporte";

	const handleGenerateReport = async () => {
		try {
			onReportPreview(null);
			const result = await createReport({final_date: finalDate, initial_date: initialDate, platform_retention, pool_influencer_share, force, preview}).unwrap();
			onReportPreview(result.report || null);
		} catch (error) {
			toast.error("Error generando el reporte.")
		}			
	}

	return (
		<Popover open={open} onOpenChange={setOpen} modal>
			<PopoverTrigger asChild>
				<Button variant="default">{label}</Button>
			</PopoverTrigger>
			<PopoverContent className={cn("z-[999] -mt-12 flex w-96 flex-col gap-2", {"mr-6": force})}>
				<div className="flex items-center gap-2">
					<span>Comisión de la Plataforma</span>
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger asChild>
								<div className="flex items-center gap-2">
									<QuestionMarkCircle className="h-4 w-4" />
								</div>
							</TooltipTrigger>
							<TooltipContent>
								<p className="w-96">
									Porcentaje de las ganancias que la plataforma retiene antes de distribuir el restante a los asociados y partners.
								</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
					<span className="ml-auto flex aspect-square w-11 items-center justify-center bg-neutral-200 p-2 text-xs">
						{Math.round(platform_retention * 100)}%
					</span>
				</div>
				<div className="flex items-center gap-4 text-xs">
					<span>0%</span>
					<Slider
						value={platform_retention}
						onChange={e => {
							setRetention(Number(e.target.value));
						}}
						min={0}
						max={1}
						step={0.01}
					/>
					<span>100%</span>
				</div>

				<div className="flex items-center gap-2">
					<span>Fondo para Influencers</span>
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger asChild>
								<div className="flex items-center gap-2">
									<QuestionMarkCircle className="h-4 w-4" />
								</div>
							</TooltipTrigger>
							<TooltipContent>
								<p className="w-96">
									Porcentaje del total restante después de aplicar la retención de la plataforma, destinado a ser distribuido entre
									todos los influencers.
								</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
					<span className="ml-auto flex aspect-square w-11 items-center justify-center bg-neutral-200 p-2 text-xs">
						{Math.round(pool_influencer_share * 100)}%
					</span>
				</div>
				<div className="flex items-center gap-4 text-xs">
					<span>0%</span>
					<Slider
						value={pool_influencer_share}
						onChange={e => {
							setInfluencerPool(Number(e.target.value));
						}}
						min={0}
						max={1}
						step={0.01}
					/>
					<span>100%</span>
				</div>
				{isPreview && (
					<div className="flex items-center gap-4 text-xs mt-4">
						<Switch
							checked={preview}
							onCheckedChange={c => {
								setPreview(c);
							}}
						/>
						<Label>Solo vista previa (no guardar)</Label>
						<TooltipProvider>
							<Tooltip>
								<TooltipTrigger asChild>
									<div className="flex items-center gap-2">
										<QuestionMarkCircle className="h-4 w-4" />
									</div>
								</TooltipTrigger>
								<TooltipContent>
									<p className="w-96">
										Genera un reporte de vista previa sin guardar los datos en la base de datos.
									</p>
								</TooltipContent>
							</Tooltip>
						</TooltipProvider>
					</div>
				)}
				<Button
					className="mt-6"
					onClick={handleGenerateReport}
				>
					{isLoading ? <LoadingDots color="white" /> : "Generar reporte"}
				</Button>
			</PopoverContent>
		</Popover>
	);
};

export default Report;
