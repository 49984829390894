import {Fragment, useEffect} from "react";
import {dashboardActions} from "../../../../_store/features/dashboard/dashboard-slice";
import {useAppDispatch} from "../../../../_store/hooks";
import {DatePickerWithRange} from "../../../../components/blocks/DateRangePicker";
import ShareLinkButton from "./components/share-link-button";
import useDates from "./hooks/useDates";
import IncomeInfluencerCard from "./components/income-influencer-card";
import ReferralSubscriptionBarChart from "./components/referral-subscription--bar-chart";
import Transitions from "./components/transitions";
import ReferralPerformanceLineChart from "./components/referral-performance--line-chart";
import ReferralRegistrationLineChart from "./components/referral-registration-line-chart";
import {TopViews} from "../../../../components/blocks/Stats";
import {useGetInfluencerDataQuery} from "../../../../_store/features/influencer/api";

const noop = () => {};

export function InfluencerDashboard() {
	const {data} = useGetInfluencerDataQuery({});
	const {dates, handleDateRangeChange} = useDates({
		onDateRangeChange: () => {},
	});

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(dashboardActions.getTopSeries());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			<section className="sticky top-0 z-10 flex items-center justify-between border-b border-b-border bg-background p-6 py-4">
				<h2 className="scroll-m-20 text-2xl font-extrabold tracking-tight">Dashboard Asociados</h2>

				<div className="flex items-center gap-4">
					<ShareLinkButton link={data?.link || ""} />
					<DatePickerWithRange onDateRangeChange={handleDateRangeChange} date={dates} className={`${false && "pointer-events-none"}`} />
				</div>
			</section>

			<section className="flex flex-col gap-4 p-6 py-4">
				<IncomeInfluencerCard dates={dates} />
				<ReferralSubscriptionBarChart dates={dates} />
				<Transitions dates={dates} />
				<ReferralPerformanceLineChart dates={dates} />
				<ReferralRegistrationLineChart dates={dates} />

				{(data?.related_series && data.related_series.length > 0 ) && <TopViews type="contents" serieid={data?.related_series[0].serie_id ?? ""} onClickContent={noop} />}
			</section>
		</Fragment>
	);
}
