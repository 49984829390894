import "chartjs-adapter-date-fns";
import {useMemo, useRef, useState} from "react";
import {Spinner} from "../../../../../components/primitives/icons";
import {BarElement, CategoryScale, ChartData, Chart as ChartJS, Tooltip as ChartJSTooltip, LinearScale} from "chart.js";
import {format, getWeek, parse} from "date-fns";
import {Bar} from "react-chartjs-2";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {enUS} from "date-fns/locale";
import {useAppDispatch, useAppSelector} from "../../../../../_store/hooks";
import {GROUPING} from "../../../../../constants";
import {maxGroupingRange} from "../../../../../../utils/selectGroupingRange";
import {useUsersConversionByNameQuery} from "../../../../../_store/features/users-conversion/hooks";
import {usersConvertionActions} from "../../../../../_store/features/users-conversion/user-conversion-slice";
import {chartOptions, missingData, registeredData, subscribedData, convertionPorcentData, missingPorcentData} from "./helpers";

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartJSTooltip);

const UsersConversionChart = () => {
	const chartRef = useRef<ChartJSOrUndefined<"bar", {date: string; count: number}[]>>(null);
	const dispatch = useAppDispatch();
	const {data: statistics, isLoading} = useUsersConversionByNameQuery("getUsersConversion");
	const {range, grouping} = useAppSelector(state => state.userConversion);
	const [groupEnables, setGroupEnables] = useState([GROUPING.DAILY]);

	const data: ChartData<"bar", {date: string; count: number; total: number}[]> = useMemo(() => {
		if (!statistics) return {datasets: []};
		const formatter = new Intl.DateTimeFormat("en-US", {month: "short", day: "numeric", year: "numeric"});
		let rangeLenght: number;
		let maximumValue = 0;

		subscribedData.data = [];
		registeredData.data = [];
		missingData.data = [];
		convertionPorcentData.data = [];
		missingPorcentData.data = [];

		if (grouping === GROUPING.DAILY) {
			rangeLenght = Math.ceil((new Date(range.to).getTime() - new Date(range.from).getTime()) / (24 * 60 * 60 * 1000));
			if (rangeLenght < 7) setGroupEnables([GROUPING.DAILY]);
			else if (rangeLenght < 30) setGroupEnables([GROUPING.DAILY, GROUPING.WEEKLY]);
			else setGroupEnables([GROUPING.DAILY, GROUPING.WEEKLY, GROUPING.MONTHLY]);
		} else if (grouping === GROUPING.WEEKLY) {
			rangeLenght = Math.ceil((new Date(range.to).getTime() - new Date(range.from).getTime()) / (7 * 24 * 60 * 60 * 1000));
			if (rangeLenght < 4) setGroupEnables([GROUPING.DAILY, GROUPING.WEEKLY]);
			else if (rangeLenght > maxGroupingRange / 7) setGroupEnables([GROUPING.WEEKLY, GROUPING.MONTHLY]);
			else setGroupEnables([GROUPING.DAILY, GROUPING.WEEKLY, GROUPING.MONTHLY]);
		} else if (grouping === GROUPING.MONTHLY) {
			rangeLenght =
				(new Date(range.to).getFullYear() - new Date(range.from).getFullYear()) * 12 +
				(new Date(range.to).getMonth() + 1 - new Date(range.from).getMonth());
			if (rangeLenght > maxGroupingRange / 30) setGroupEnables([GROUPING.WEEKLY, GROUPING.MONTHLY]);
			else setGroupEnables([GROUPING.DAILY, GROUPING.WEEKLY, GROUPING.MONTHLY]);
		} else {
			rangeLenght = 0;
		}

		const selectedData: {date: string; count: number}[] = Array.from({length: rangeLenght}, (_, i) => {
			var date = new Date(range.from);
			if (grouping === GROUPING.DAILY) date.setDate(date.getDate() + i);
			else if (grouping === GROUPING.WEEKLY) {
				const week = new Date().setTime(date.getTime() + i * 7 * 24 * 60 * 60 * 1000);
				const newDate = parse(getWeek(week).toString(), "I", new Date().setFullYear(new Date(week).getFullYear()), {
					useAdditionalWeekYearTokens: true,
				});

				date = new Date(newDate);
			} else if (grouping === GROUPING.MONTHLY) {
				date.setMonth(date.getMonth() + i);
			}
			return {date: formatter.format(date), count: 0};
		});

		selectedData.forEach(date => {
			let itemDate: Date;
			const registered = statistics.successful_registration.find(item => {
				if (grouping === GROUPING.DAILY && item.day && item.month && item.year) {
					itemDate = new Date(item.year, item.month - 1, item.day);
				} else if (grouping === GROUPING.WEEKLY && item.week !== null && item.year) {
					const weekDay = parse((item.week + 1).toString(), "I", new Date().setFullYear(item.year), {useAdditionalWeekYearTokens: true});
					itemDate = new Date(weekDay);
				} else if (grouping === GROUPING.MONTHLY && item.month && item.year) {
					return item.year === new Date(date.date).getFullYear() && item.month - 1 === new Date(date.date).getMonth();
				} else {
					return false;
				}
				return formatter.format(itemDate) === date.date;
			});
			const subscribed = statistics.registered_subscribed_same.find(item => {
				if (grouping === GROUPING.DAILY && item.day && item.month && item.year) {
					itemDate = new Date(item.year, item.month - 1, item.day);
				} else if (grouping === GROUPING.WEEKLY && item.week !== null && item.year) {
					const weekDay = parse((item.week + 1).toString(), "I", new Date().setFullYear(item.year), {useAdditionalWeekYearTokens: true});
					itemDate = new Date(weekDay);
				} else if (grouping === GROUPING.MONTHLY && item.month && item.year) {
					return item.year === new Date(date.date).getFullYear() && item.month - 1 === new Date(date.date).getMonth();
				} else {
					return false;
				}
				return formatter.format(itemDate) === date.date;
			});
			const missing = statistics.missing_registration.find(item => {
				if (grouping === GROUPING.DAILY && item.day && item.month && item.year) {
					itemDate = new Date(item.year, item.month - 1, item.day);
				} else if (grouping === GROUPING.WEEKLY && item.week !== null && item.year) {
					const weekDay = parse((item.week + 1).toString(), "I", new Date().setFullYear(item.year), {useAdditionalWeekYearTokens: true});
					itemDate = new Date(weekDay);
				} else if (grouping === GROUPING.MONTHLY && item.month && item.year) {
					return item.year === new Date(date.date).getFullYear() && item.month - 1 === new Date(date.date).getMonth();
				} else {
					return false;
				}
				return formatter.format(itemDate) === date.date;
			});

			const total = (registered ? registered.successful_registration : 0) + (missing ? missing.missing_registration : 0);
			maximumValue = Math.max(total, maximumValue);

			registeredData.data.push({
				...date,
				total,
				count: registered ? registered.successful_registration - (subscribed?.registered_subscribed_same ?? 0) : 0,
			});
			subscribedData.data.push({...date, total, count: subscribed ? subscribed.registered_subscribed_same : 0});
			missingData.data.push({...date, total, count: missing ? missing.missing_registration : 0});
		});

		subscribedData.data.map(item => {
			convertionPorcentData.data.push({
				date: item.date,
				count: item.total ? (item.count * maximumValue) / item.total : 0,
				total: maximumValue,
			});
			return null;
		});

		missingData.data.map(item => {
			missingPorcentData.data.push({
				date: item.date,
				count: item.total ? (item.count * maximumValue) / item.total : 0,
				total: maximumValue,
			});
			return null;
		});

		return {
			labels: selectedData.map(data => data.date),
			datasets: [subscribedData, registeredData, missingData, convertionPorcentData, missingPorcentData],
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statistics]);

	const handleGroupingChange = (grouping: GROUPING) => {
		dispatch(usersConvertionActions.getUsersConversion({initial_date: range.from, final_date: range.to, grouping}));
	};

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 md:grid-cols-2 lg:grid-cols-7">
			<div className="relative col-span-7 rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Funnel de Conversión de usuarios</h3>
				</div>
				{isLoading ? (
					<div className="flex h-[350px] items-center justify-center">
						<Spinner />
					</div>
				) : (
					<>
						<div className="relative p-6 pt-0">
							<Bar
								height={350}
								ref={chartRef}
								options={{
									...chartOptions,
									scales: {
										x: {
											ticks: {
												callback(tickValue) {
													let value = this.getLabelForValue(tickValue as any);
													const date: Date = new Date(value);
													let formattedDate: string;
													formattedDate = format(date, grouping === GROUPING.MONTHLY ? "MMM" : "MMM d", {locale: enUS});
													return formattedDate;
												},
											},
											grid: {
												color: "transparent",
											},
											stacked: true,
										},
										y: {
											ticks: {
												precision: 1,
											},
											stacked: true,
										},
									},
								}}
								data={data}
							/>
						</div>
						<div className="absolute right-0 top-0 flex justify-center space-x-2 p-4">
							<button
								onClick={() => {
									handleGroupingChange(GROUPING.DAILY);
								}}
								className={`${grouping === GROUPING.DAILY ? "pointer-events-none bg-transparent shadow" : "bg-slate-200"} ${
									groupEnables.includes(GROUPING.DAILY) ? "" : "pointer-events-none opacity-40"
								} rounded border px-4 py-1`}
							>
								Diario
							</button>
							<button
								onClick={() => {
									handleGroupingChange(GROUPING.WEEKLY);
								}}
								className={`${grouping === GROUPING.WEEKLY ? "pointer-events-none bg-transparent shadow" : "bg-slate-200"} ${
									groupEnables.includes(GROUPING.WEEKLY) ? "" : "pointer-events-none opacity-40"
								} rounded border px-4 py-1`}
							>
								Semanal
							</button>
							<button
								onClick={() => {
									handleGroupingChange(GROUPING.MONTHLY);
								}}
								className={`${grouping === GROUPING.MONTHLY ? "pointer-events-none bg-transparent shadow" : "bg-slate-200"} ${
									groupEnables.includes(GROUPING.MONTHLY) ? "" : "pointer-events-none opacity-40"
								} rounded border px-4 py-1`}
							>
								Mensual
							</button>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default UsersConversionChart;
