import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {createAppAsyncThunk} from "../../hooks";
import {auth} from "../../../firebase";
import {GROUPING} from "../../../constants";
import {GetUsersConversionParams, GetUsersConversionResponse} from "../../../data-access/users-conversion/types";
import {getUsersConversionStatistics} from "../../../data-access/users-conversion/statistics";

type RequestState = "pending" | "fulfilled" | "rejected";
export type UsersConversionQueries = "getUsersConversion";

export type DataByName = {
	getUsersConversion: GetUsersConversionResponse;
};

export interface usersConversionInterface {
	loading: boolean;
	statusByName: Record<UsersConversionQueries, RequestState | undefined>;
	dataByName: {
		[k in keyof DataByName]: DataByName[k] | undefined;
	};
	range: {
		from: string;
		to: string;
	};
	grouping: GROUPING;
}

const initialState: usersConversionInterface = {
	loading: false,
	statusByName: {
		getUsersConversion: undefined,
	},
	dataByName: {
		getUsersConversion: undefined,
	},
	range: {
		from: "",
		to: "",
	},
	grouping: 1,
};

const getUsersConversion = createAppAsyncThunk("dashboard/get-users-conversion", async (params: GetUsersConversionParams, thunkAPI) => {
	try {
		const token = await auth.currentUser?.getIdToken();
		if (!token) return;

		const res = await getUsersConversionStatistics(token, {
			...params,
		});
		return {
			data: res.data,
			range: {from: params.initial_date, to: params.final_date},
			grouping: params.grouping,
		};
	} catch (error: any) {
		console.log(error);
		return thunkAPI.rejectWithValue("Problem fetching contents");
	}
});

const usersConvertionSlice = createSlice({
	name: "dashboard",
	initialState,
	reducers: {
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
		setRange(state, action: PayloadAction<{from: string; to: string}>) {
			state.range = action.payload;
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getUsersConversion.pending, (state, action) => {
				state.statusByName["getUsersConversion"] = "pending";
			})
			.addCase(getUsersConversion.fulfilled, (state, action) => {
				state.statusByName["getUsersConversion"] = "fulfilled";
				state.dataByName["getUsersConversion"] = action.payload?.data ?? undefined;
				state.range = action.payload?.range ?? {from: "", to: ""};
				state.grouping = action.payload?.grouping ?? 1;
			})
			.addCase(getUsersConversion.rejected, (state, action) => {
				state.statusByName["getUsersConversion"] = "rejected";
			});
	},
});

export const usersConvertionActions = {
	...usersConvertionSlice.actions,
	getUsersConversion,
};

export default usersConvertionSlice;
