import {useState} from "react";
import {GoogleMap, InfoWindowF, Libraries, MarkerF, useLoadScript} from "@react-google-maps/api";
import {useDashboardByNameQuery} from "../../../../_store/features/dashboard/hooks";
import Loader from "../../../../components/blocks/Stats/SponsorAdViews/Loader";
import {getRadio} from "../../../../../utils/getMapMarkerRadio";

export interface LocationInterface {
	latitude: string;
	location: string;
	longitude: string;
	view_count: number;
}

const libraries: Libraries = ["places"];

export default function AppConsuptionWorldMap() {
	const [globalDetails, setGlobalDetails] = useState(true);

	return (
		<div className="mb-5 flex flex-1 flex-col rounded-lg border bg-card">
			<div className="flex flex-row items-center justify-between">
				<h3 className="p-8 text-left text-lg font-semibold leading-none tracking-tight">Consumo de la Plataforma.</h3>
				<div className="flex flex-row items-center justify-between gap-x-2 p-8">
					<button
						onClick={() => setGlobalDetails(true)}
						className={`${globalDetails ? "pointer-events-none bg-transparent shadow" : "bg-slate-200"} rounded border px-4 py-1`}
					>
						Global
					</button>
					<button
						onClick={() => setGlobalDetails(false)}
						className={`${!globalDetails ? "pointer-events-none bg-transparent shadow" : "bg-slate-200"} rounded border px-4 py-1`}
					>
						US Only
					</button>
				</div>
			</div>
			<div className="flex items-center justify-between border-b border-border" />

			{globalDetails ? <GlobalGoogleMap /> : <UsGoogleMap />}
		</div>
	);
}

function GlobalGoogleMap() {
	const [activeMarker, setActiveMarker] = useState<LocationInterface | null>(null);

	const appInstallations = useDashboardByNameQuery("appConsumptionByLocation");
	const {isLoaded} = useLoadScript({
		googleMapsApiKey: (process.env.REACT_APP_GOOGLE_MAPS_API_KEY + "&loading=async") as string,
		libraries: libraries,
	});

	return isLoaded && !appInstallations.isLoading ? (
		<GoogleMap
			center={{lat: 0, lng: 0}}
			zoom={3}
			mapContainerStyle={{height: "45vw", width: "100%"}}
			options={{
				mapTypeControl: false,
				streetViewControl: false,
				zoomControlOptions: {
					position: 9,
				},
				minZoom: 3,
				maxZoom: 5,
			}}
		>
			{appInstallations.data &&
				appInstallations.data.global_installations_by_location.map((item, index: number) => {
					if (item.latitude && item.longitude && appInstallations.data?.max_view_count) {
						return (
							<MarkerF
								key={index}
								position={{lat: Number(item.latitude), lng: Number(item.longitude)}}
								icon={{
									path: "M 0,0 m -2,0 a 2,2 0 1,0 4,0 a 2,2 0 1,0 -4,0",
									fillColor: "#FF0000",
									fillOpacity: 0.4,
									strokeWeight: 0,
									scale: getRadio(item.view_count, appInstallations.data?.max_view_count / 20),
								}}
								onClick={() => setActiveMarker(item)}
							>
								{activeMarker && activeMarker === item ? (
									<InfoWindowF onCloseClick={() => setActiveMarker(null)}>
										<div className="my-2 flex flex-col gap-1">
											<p className="text-start ">
												<b>Region:</b> {activeMarker.location}
											</p>
											<p className="text-start">
												<b>Horas de Streaming:</b> {activeMarker.view_count}
											</p>
										</div>
									</InfoWindowF>
								) : null}
							</MarkerF>
						);
					} else {
						return null;
					}
				})}
		</GoogleMap>
	) : (
		<Loader />
	);
}

function UsGoogleMap() {
	const [activeMarker, setActiveMarker] = useState<LocationInterface | null>(null);

	const appInstallations = useDashboardByNameQuery("appConsumptionByLocation");
	const {isLoaded} = useLoadScript({
		googleMapsApiKey: (process.env.REACT_APP_GOOGLE_MAPS_API_KEY + "&loading=async") as string,
		libraries: libraries,
	});

	return isLoaded && !appInstallations.isLoading ? (
		<GoogleMap
			center={{lat: 37.0902, lng: -95.7129}}
			zoom={3}
			mapContainerStyle={{height: "45vw", width: "100%"}}
			options={{
				mapTypeControl: false,
				streetViewControl: false,
				zoomControlOptions: {
					position: 9,
				},
				minZoom: 5,
				maxZoom: 8,
			}}
		>
			{appInstallations.data?.us_installations_by_location.map((item, index: number) => {
				if (item.latitude && item.longitude && appInstallations.data?.max_view_count) {
					return (
						<MarkerF
							key={index}
							position={{lat: Number(item.latitude), lng: Number(item.longitude)}}
							icon={{
								path: "M 0,0 m -2,0 a 2,2 0 1,0 4,0 a 2,2 0 1,0 -4,0",
								fillColor: "#FF0000",
								fillOpacity: 0.4,
								strokeWeight: 0,
								scale: getRadio(item.view_count, appInstallations.data?.us_max_view_count / 80),
							}}
							onClick={() => setActiveMarker(item)}
						>
							{activeMarker && activeMarker === item ? (
								<InfoWindowF onCloseClick={() => setActiveMarker(null)}>
									<div className="my-2 flex flex-col gap-1">
										<p className="text-start ">
											<b>Region:</b> {activeMarker.location}
										</p>
										<p className="text-start">
											<b>Horas de Streaming:</b> {activeMarker.view_count}
										</p>
									</div>
								</InfoWindowF>
							) : null}
						</MarkerF>
					);
				} else {
					return null;
				}
			})}
		</GoogleMap>
	) : (
		<Loader />
	);
}
