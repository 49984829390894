import axios, {AxiosResponse} from "axios";
import {GetUsersConversionResponse, GetUsersConversionParams} from "./types";

const axiosHandler = axios.create({baseURL: `${process.env.REACT_APP_APP_DOMAIN}`});

export async function getUsersConversionStatistics(
	token: string,
	params?: GetUsersConversionParams,
): Promise<AxiosResponse<GetUsersConversionResponse>> {
	const idToken = `Bearer ${token}`;

	return await axiosHandler.get("/get_users_conversion_statistics2", {
		headers: {
			Accept: "*/*",
			Authorization: idToken,
		},
		params,
	});
}
