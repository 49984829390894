import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from "react";

const currentDate = new Date();
const currentMonth = currentDate.getMonth();
const currentYear = currentDate.getFullYear();
const startYear = 2023;
const startMonth = 10;

const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

interface SelectMonthProps {
	onChangeMonth: Dispatch<SetStateAction<any>>;
}

const SelectMonth = ({onChangeMonth}: SelectMonthProps) => {
	const [year, setYear] = useState<number>(currentYear);
	const [month, setMonth] = useState<number>(currentMonth);

	const queryDate = useMemo(() => {
		return `${year}-${(month + 1).toString().padStart(2, "0")}-01`;
	}, [month, year]);

	useEffect(() => {
		onChangeMonth(queryDate);
	}, [onChangeMonth, queryDate]);

	const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedYear = Number(e.target.value);
		setYear(selectedYear);

		if (selectedYear === startYear && month < startMonth) {
			setMonth(startMonth);
		} else if (selectedYear === currentYear && month > currentMonth) {
			setMonth(currentMonth);
		}
	};

	const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setMonth(Number(e.target.value));
	};

	const availableYears = Array.from({length: currentYear - startYear + 1}, (_, i) => startYear + i);

	const availableMonths = months.filter((_, index) => {
		if (year === startYear) return index >= startMonth;
		if (year === currentYear) return index <= currentMonth;
		return true;
	});

	return (
		<div className="flex items-center gap-4">
			<div className="flex items-center gap-2">
				<select className="rounded-md border border-border p-2" onChange={handleYearChange} value={year}>
					{availableYears.map(y => (
						<option key={y} value={y}>
							{y}
						</option>
					))}
				</select>

				<select className="rounded-md border border-border p-2" onChange={handleMonthChange} value={month}>
					{availableMonths.map((monthName, index) => {
						const realIndex = year === startYear ? index + startMonth : index;
						return (
							<option key={monthName} value={realIndex}>
								{monthName}
							</option>
						);
					})}
				</select>
			</div>
		</div>
	);
};

export default SelectMonth;
