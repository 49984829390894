import {useEffect} from "react";
import Chart, {ChartData, ChartOptions} from "chart.js/auto";
import {useDashboardCorporateByNameQuery} from "../../../../_store/features/dashboard-corporate/hooks";
import {useMemo} from "react";
import {format, isSameDay} from "date-fns";
import {Spinner} from "../../../primitives/icons";
import {DateRange} from "react-day-picker";
import {enUS} from "date-fns/locale";

interface IncomeVsProfitProps {
	dates: DateRange | undefined;
}

interface ChartDataInterface {
	date: string;
	profit: number;
}

const IncomeVsProfit = ({dates}: IncomeVsProfitProps) => {
	const {data: statistics, isLoading} = useDashboardCorporateByNameQuery("getGeneralIncomeAndProfitV2");

	const data: ChartData<"line", {date: string; profit: number}[]> = useMemo(() => {
		const mergeDailyProfitSubscriptions = statistics?.daily_profit_subscriptions?.map(
			({date, profit_slots, profit_suscriptions, profit_suscriptions_renewal, subscription_fee, subscription_renewal_fee, slots_fee}) => {
				const profit = profit_slots + profit_suscriptions + profit_suscriptions_renewal;
				const fee = subscription_fee + subscription_renewal_fee + slots_fee;
				return {date, profit, fee};
			},
		);

		const mergedData = [
			...(statistics?.daily_profit_memberships || []),
			...(statistics?.daily_cost || []),
			...(statistics?.daily_profit_coins || []),
			...(mergeDailyProfitSubscriptions || []),
			...(statistics?.daily_profit_rentals || []),
		];

		const statisticsDataSorted = mergedData?.slice().sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

		const accumulatedProfits: number[] =
			statisticsDataSorted?.reduce((acc: number[], {profit, production_cost, fee}: any) => {
				if (profit) {
					const netWorth = profit; // - fee; removed to show only incomes
					const updatedProfit = acc.length === 0 ? netWorth : acc[acc.length - 1] + netWorth;
					acc.push(updatedProfit);
				} else if (production_cost) {
					const updatedProfit = acc.length === 0 ? -production_cost : acc[acc.length - 1] - production_cost;
					acc.push(updatedProfit);
				}

				return acc;
			}, []) ?? [];

		const statisticsData =
			statisticsDataSorted?.map(({date}, index) => ({
				date: format(new Date(date), "MMM d").toLowerCase(),
				profit: accumulatedProfits[index] * 0.01,
			})) ?? [];

		const findMaxProfitsByDate = (statisticsData: ChartDataInterface[]): ChartDataInterface[] => {
			const maxProfitsByDate: Record<string, ChartDataInterface> = {};

			statisticsData.forEach(({date, profit}) => {
				if (!maxProfitsByDate[date] || profit > maxProfitsByDate[date].profit) {
					maxProfitsByDate[date] = {date, profit};
				}
			});

			return Object.values(maxProfitsByDate);
		};

		const groupedData = findMaxProfitsByDate(statisticsData);
		const fillerData = groupedData.map(({date}) => ({date, profit: 0}));
		const charData = fillerData.map(fillEl => groupedData.find(statEl => isSameDay(new Date(fillEl.date), new Date(statEl.date))) ?? fillEl);

		return {
			datasets: [
				{
					label: "Costo Vs Ganancia",
					data: charData,
					borderRadius: 4,
					parsing: {xAxisKey: "date", yAxisKey: "profit"},
				},
			],
		};
	}, [statistics]);

	const options: ChartOptions<"line"> = {
		scales: {
			y: {
				beginAtZero: false,
			},
			x: {
				ticks: {
					callback(tickValue) {
						const date = new Date(this.getLabelForValue(tickValue as any));
						// Format the date based on the available information
						return format(date, "MMM d", {locale: enUS}).toLowerCase();
					},
				},
			},
		},
		onHover: (event, activeElements) => {
			(event?.native?.target as HTMLElement).style.cursor = activeElements?.length > 0 ? "pointer" : "auto";
		},
		plugins: {
			tooltip: {
				enabled: true,
				callbacks: {
					title(tooltipItems) {
						if (tooltipItems[0].label) {
							return format(new Date(tooltipItems[0].label), "MMMM - d", {locale: enUS});
						}
					},
					label: function (context) {
						let label = context.dataset.label || "";

						if (label) {
							label += ": ";
						}
						if (context.parsed.y !== null) {
							label += new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(context.parsed.y);
						}

						return label;
					},
				},
				mode: "index",
			},
			legend: {
				display: true,
				position: "top",
				align: "center",
				labels: {
					generateLabels(chart) {
						const legend = [
							{fillStyle: "red", text: "Relación de gastos e ingresos negativa"},
							{fillStyle: "green", text: "Relación de gastos e ingresos positiva"},
						];

						return legend;
					},
				},
				onClick: (e, legendItem, legend) => {},
			},
		},
		responsive: true,
	};

	useEffect(() => {
		if (!data || isLoading) return;

		const chart = new Chart(document.getElementById("myChart") as HTMLCanvasElement, {
			type: "line",
			data: data,
			options: options,
			plugins: [
				{
					id: "customLineColorPlugin",
					beforeRender: chart => {
						const ctx = chart.ctx;
						const yAxis = chart.scales["y"];
						const yPos = yAxis.getPixelForValue(0);
						const offset = yPos / chart.height;

						const gradientFill = ctx.createLinearGradient(0, 0, 0, chart.height);
						if (offset < 0) {
							gradientFill.addColorStop(0, "rgb(229,66,66)");
							gradientFill.addColorStop(1, "rgb(229,66,66)");
						} else if (offset > 1) {
							gradientFill.addColorStop(0, "rgb(86,188,77)");
							gradientFill.addColorStop(1, "rgb(86,188,77)");
						} else {
							gradientFill.addColorStop(0, "rgb(86,188,77)");
							gradientFill.addColorStop(offset, "rgb(86,188,77)");
							gradientFill.addColorStop(offset, "rgb(229,66,66)");
							gradientFill.addColorStop(1, "rgb(229,66,66)");
						}

						const datasets = chart.data.datasets;
						datasets.forEach(dataset => {
							dataset.borderColor = gradientFill;
						});
					},
				},
			],
		});

		return () => {
			chart.destroy();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates, statistics]);

	return (
		<div className="w-full">
			{isLoading ? (
				<div className="flex h-[400px] items-center justify-center rounded-lg border bg-card p-5">
					<Spinner />
				</div>
			) : (
				<>
					<h3 className="absolute ml-6 mt-6 text-lg font-semibold leading-none tracking-tight">Gráfica Costos Vs Ingresos Acumulados.</h3>
					<canvas id="myChart" width={350} height={110} className="h-[450px] w-full rounded-lg border bg-card p-8 pt-12"></canvas>
				</>
			)}
		</div>
	);
};

export default IncomeVsProfit;
