import {Button} from "../../../../../components/primitives/Button";
import {Spinner, Report as ReportIcon} from "../../../../../components/primitives/icons";
import {usePostCreateInfluencerReportMutation} from "../../../../../_store/features/influencer/api";
import toast from "react-hot-toast";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";

interface GenerateReportButtonProps {
	force?: boolean;
	isCorporate?: boolean;
	influencer?: string;
	queryDate?: string;
}

function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
	return typeof error === "object" && error != null && "status" in error;
}

function isErrorWithMessage(error: unknown): error is {message: string} {
	return typeof error === "object" && error != null && "message" in error && typeof (error as any).message === "string";
}

const GenerateReportButton = (props: GenerateReportButtonProps) => {
	const {force = false, isCorporate, influencer, queryDate} = props;
	const [createReport, {isLoading}] = usePostCreateInfluencerReportMutation();

	const text = force ? "Volver a generar" : "Generar reporte";

	const handleOnGenerateReport = async () => {
		if (!queryDate) return;

		const [y, m] = queryDate.split("-");
		const lastDay = new Date(Number(y), Number(m), 0).getDate();

		try {
			await createReport({
				influencer_id: influencer!,
				initial_date: `${y}-${m}-01`,
				final_date: `${y}-${m}-${lastDay}`,
				force,
			}).unwrap();

			toast.success("Reporte creado correctamente");
		} catch (err) {
			if (isFetchBaseQueryError(err)) {
				const errMsg = "error" in err ? err.error : JSON.stringify((err.data as unknown as any).error);
				toast.error(errMsg.replaceAll('"', ""));
			} else if (isErrorWithMessage(err)) {
				toast.error(err.message);
			}
		}
	};

	if (!isCorporate) return null;

	return (
		<div className="flex items-center justify-center">
			<Button onClick={handleOnGenerateReport} variant={"blueBtn"} size={"sm"} type={"button"} disabled={isLoading}>
				<div className="flex items-center space-x-2">
					{isLoading ? <Spinner /> : <ReportIcon className="h-4 w-4" />}
					<span className="flex items-center text-sm font-medium">{text}</span>
				</div>
			</Button>
		</div>
	);
};

export default GenerateReportButton;
