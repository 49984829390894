import {ChartData, ChartOptions} from "chart.js";
import {useMemo, useRef} from "react";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {Line} from "react-chartjs-2";
import {LineElement, CategoryScale, Chart as ChartJS, Tooltip as ChartJSTooltip, LinearScale} from "chart.js";
import {differenceInCalendarDays, endOfMonth, format, formatISO9075} from "date-fns";
import {es} from "date-fns/locale";
import {utcToZonedTime} from "date-fns-tz";
import {Report} from "../../../../_store/features/influencer/types";
import {Spinner} from "../../../../components/primitives/icons";

ChartJS.register(CategoryScale, LinearScale, LineElement, ChartJSTooltip);

interface InfluencerBarChartProps {
	report: Report;
	report_date: string;
	isLoading: boolean;
}

interface Point {
	date: string;
	value: number;
}

const ReferralPerformanceLineChart = ({isLoading, report, report_date}: InfluencerBarChartProps) => {
	const chartRef = useRef<ChartJSOrUndefined<"line", Point[]>>(null);

	const views = report.views || [];

	const total_seconds = views.reduce((acc, curr) => (acc += curr.seconds), 0);

	const data: ChartData<"line", Point[]> | null = useMemo(() => {
		const initialDate = utcToZonedTime(report_date, "UTC");
		const finalDate = endOfMonth(initialDate);

		const diffDays = differenceInCalendarDays(finalDate, initialDate) + 1;

		const selectedData: Point[] = Array.from({length: diffDays}, (_, i) => {
			var date = new Date(initialDate);
			date.setDate(date.getDate() + i);

			return {date: formatISO9075(date, {representation: "date"}), value: 0, total: 0};
		});

		for (const detail of views) {
			const index = selectedData.findIndex(data => data.date === detail.view_date);

			if (index !== -1) {
				selectedData[index].value = Number(detail.seconds / 60 / 60);
			}
		}

		return {
			labels: selectedData.map(data => data.date),
			datasets: [
				{
					label: "Tiempo de reproducción",
					data: selectedData,
					fill: false,
					backgroundColor: "#bb4422",
					borderColor: "#bb4422",
					borderWidth: 1,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "value",
					},
				},
			],
		};
	}, [report.views, report_date]);

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 pt-6 md:grid-cols-2 lg:grid-cols-7">
			<div className="relative col-span-7 rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Tiempo de reproducción</h3>
				</div>
				{isLoading ? (
					<div className="flex h-[350px] items-center justify-center">
						<Spinner />
					</div>
				) : (
					<div className="relative p-6 pt-0">
						<Line
							height={350}
							ref={chartRef}
							data={data}
							options={{
								...options,
								scales: {
									x: {
										ticks: {
											callback(tickValue) {
												let value = this.getLabelForValue(tickValue as any);
												const date: Date = new Date(`${value}T00:00:00`);
												let formattedDate: string;

												formattedDate = format(date, "MMM d", {locale: es}).toLowerCase();

												return formattedDate;
											},
										},
										grid: {
											color: "transparent",
										},
										stacked: true,
									},
									y: {
										ticks: {
											precision: 1,
										},
										stacked: true,
									},
								},
							}}
						/>
						<div className="absolute -top-10 right-10 text-xs">
							<div>
								<div>
									<b>Series relacionadas:</b> {Math.round(total_seconds / 3600) ?? 0} horas
								</div>
							</div>
							<div>
								<div>
									<b>Plataforma:</b> {Math.round(report.total_seconds_platform / 3600)} horas
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default ReferralPerformanceLineChart;

const options: ChartOptions<"line"> = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		tooltip: {
			callbacks: {
				title(tooltipItems) {
					return "Tiempo de reproducción - " + tooltipItems[0].label;
				},
				label(context) {
					const item = context.dataset.data[context.dataIndex] as unknown as Point;

					if (!item) return "";

					return "Tiempo de reproducción: " + Math.round(item.value) + " horas";
				},
			},
		},
		legend: {
			display: false,
		},
	},
	scales: {
		x: {
			ticks: {
				callback(tickValue) {
					return format(new Date(this.getLabelForValue(tickValue as any)), "MMM d", {locale: es}).toLowerCase();
				},
			},
			grid: {
				color: "transparent",
			},
		},
	},
};
