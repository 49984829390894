import {useMemo, useState} from "react";
import {DataTable} from "../../../../../components/blocks/DataTable";
import {createColumnHelper, PaginationState} from "@tanstack/react-table";
import {DataTableColumnHeader} from "../../../../../components/primitives/DataTable";
import {formattedDayAndHour} from "../../../../../../utils/formattedDayAndHour";
import {SUBSCRIPTIONS_TYPES} from "../../../../../constants";
import {DateRange} from "react-day-picker";
import {useGetInfluencerDataQuery, useGetInfluencerTransitionsQuery} from "../../../../../_store/features/influencer/api";
import {formatISO9075} from "date-fns";
import {InfluencerTransitionData} from "../../../../../_store/features/influencer/types";

const columnHelper = createColumnHelper<InfluencerTransitionData>();

interface TransitionsProps {
	dates: DateRange;
}

const Transitions = ({dates}: TransitionsProps) => {
	const {data: influencerMetadata, isLoading: isLoadingMetadata} = useGetInfluencerDataQuery({});
	const {data, isLoading} = useGetInfluencerTransitionsQuery({
		final_date: formatISO9075(dates.to!),
		initial_date: formatISO9075(dates.from!),
	});

	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});

	const [tableRef, setTableRef] = useState<HTMLDivElement | null>(null);

	const columns = useMemo(() => {
		let colWidth = Math.floor((tableRef?.getBoundingClientRect().width ?? 0) / 7);

		return [
			columnHelper.accessor("name", {
				id: "Usuario",
				header: ({column}) => <DataTableColumnHeader title="Usuario" column={column} />,
				cell: info => (
					<div className="flex flex-col">
						<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.name}</span>
					</div>
				),
				size: colWidth,
			}),
			columnHelper.accessor("created_at", {
				id: "Fecha",
				header: ({column}) => <DataTableColumnHeader title="Fecha" column={column} />,
				cell: info => {
					const formattedTime = formattedDayAndHour(info.row.original.created_at);

					return (
						<div className="flex flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{formattedTime}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("subscription_type", {
				id: "Compra",
				header: ({column}) => <DataTableColumnHeader title="Compra" column={column} />,
				cell: info => {
					const subscriptionType = SUBSCRIPTIONS_TYPES.find(type => type.value === info.row.original.subscription_type);
					const label = `Suscripción ${subscriptionType?.label}`;

					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{label}</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("price", {
				id: "Precio de venta",
				header: ({column}) => <DataTableColumnHeader title="Precio de venta" column={column} />,
				cell: info => {
					const price = info.row.original.price / 100;

					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">
								{new Intl.NumberFormat("en-US", {
									style: "currency",
									currency: "USD",
								}).format(price)}
							</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("fee", {
				id: "Fees",
				header: ({column}) => <DataTableColumnHeader title="Impuestos de pago" column={column} />,
				cell: info => {
					const fee = info.row.original.fee / 100;

					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">
								{new Intl.NumberFormat("en-US", {
									style: "currency",
									currency: "USD",
								}).format(fee)}
							</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("price", {
				id: "Ganancias",
				header: ({column}) => <DataTableColumnHeader title="Ganancias aprox." column={column} />,
				cell: info => {
					const price = info.row.original.price / 100;
					const comission =
						(info.row.original.subscription_type === 0
							? influencerMetadata?.month_subscription_commission ?? 0
							: influencerMetadata?.annual_subscription_commission ?? 0) / 100;

					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">
								{new Intl.NumberFormat("en-US", {
									style: "currency",
									currency: "USD",
								}).format(price * comission)}
							</span>
						</div>
					);
				},
				size: colWidth,
			}),
			columnHelper.accessor("platform", {
				id: "Tienda",
				header: ({column}) => <DataTableColumnHeader title="Tienda" column={column} />,
				cell: info => {
					return (
						<div className="flex w-full flex-col">
							<span className="ml-2 overflow-hidden text-ellipsis text-left">{info.row.original.platform}</span>
						</div>
					);
				},
				size: colWidth,
			}),
		];
	}, [influencerMetadata, tableRef]);

	return (
		<div>
			<div className="mt-4 flex items-center justify-between px-6">
				<h3 className="text-lg font-semibold leading-none tracking-tight">Últimas compras registradas.</h3>
			</div>

			<div className="mt-4 flex grow-0 flex-col">
				<DataTable
					ref={setTableRef}
					columns={columns}
					dataset={data!}
					pageCount={Math.ceil(([]?.length ?? 0) / pagination.pageSize)}
					pagination={pagination}
					onPaginationChange={setPagination}
					manualPagination={false}
					showPagination={false}
					loading={isLoading || isLoadingMetadata}
				/>
			</div>
		</div>
	);
};

export default Transitions;
