import "chartjs-adapter-date-fns";
import {formatISO9075} from "date-fns";
import {Dispatch, RefObject, SetStateAction, useEffect, useMemo, useRef, useState} from "react";

import {useAppDispatch, useAppSelector} from "../../../../_store/hooks";
import {useDashboardByNameQuery} from "../../../../_store/features/dashboard/hooks";
import {dashboardActions} from "../../../../_store/features/dashboard/dashboard-slice";
import {Card} from "../Card";
import {Spinner} from "../../../primitives/icons";
import {DateRange} from "react-day-picker";

import {es} from "date-fns/locale";
import {oneMonth} from "../../../../../utils/barGraphTransformData";

import {BarElement, CategoryScale, ChartData, Chart as ChartJS, Tooltip as ChartJSTooltip, LinearScale} from "chart.js";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {addDays, differenceInDays, differenceInMonths, endOfDay, format, isSameDay, isSameMonth} from "date-fns";
import {Bar} from "react-chartjs-2";

interface SerieIncomeAndProfitProps {
	influencerReferralCode?: string;
	seriesId?: string;
	dates: DateRange | undefined;
	setSelectedDate: Dispatch<SetStateAction<DateRange>>;
}

export function InfluencerIncome({influencerReferralCode, seriesId, dates, setSelectedDate}: SerieIncomeAndProfitProps) {
	const chartRef = useRef<ChartJSOrUndefined<"bar", {date: string; value: number}[]>>(null);
	const {data: statistics, isLoading: isStatisticsLoading} = useDashboardByNameQuery("influencerIncome");

	const influencerUserCount = useAppSelector(state => state.user.userInfo?.get_influencer_data?.count_users ?? 0);

	const [showByMonths, setShowByMonths] = useState(false);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!influencerReferralCode) return;
		const durationInMilliseconds = dates ? dates?.to!.getTime() - dates?.from!.getTime() : 0;
		setShowByMonths(durationInMilliseconds > oneMonth);

		if (seriesId) {
			dispatch(
				dashboardActions.getInfluencerSerieIncomeAndProfit({
					seriesid_list: seriesId,
					referral_code: influencerReferralCode,
					initial_date: formatISO9075(dates?.from!),
					final_date: formatISO9075(dates?.to!),
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates]);

	const percentage = useMemo(() => {
		const weightedPercentage = statistics?.weighted_percentage ? statistics.weighted_percentage * 0.01 : 0.5;
		return weightedPercentage;
	}, [statistics]);

	const handleDateRangeChange = (s: number, e: number, selectedDate?: DateRange) => {
		setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)});
	};

	const total_profit = useMemo(() => {
		const membershipProfit = statistics?.historic_membership_profit ? statistics.historic_membership_profit : 0;
		const subscriptionProfit = statistics?.historic_subscription_profit ? statistics.historic_subscription_profit : 0;
		const total = membershipProfit * 0.01 + subscriptionProfit * 0.01;

		return total;
	}, [statistics]);

	const range_profit = useMemo(() => {
		const membershipProfit = statistics?.range_membership_profit ? statistics.range_membership_profit : 0;
		const subscriptionProfit = statistics?.range_subscription_profit ? statistics.range_subscription_profit : 0;
		const total = membershipProfit * 0.01 + subscriptionProfit * 0.01;
		return total;
	}, [statistics]);

	const historic_membership_profit = useMemo(() => {
		const membershipProfit = statistics?.historic_membership_profit ? statistics.historic_membership_profit : 0;
		const membershipFee = statistics?.historic_membership_fee ? statistics.historic_membership_fee : 0;
		const total = membershipProfit * 0.01 - membershipFee * 0.01;

		return total;
	}, [statistics]);

	const historic_subscription_profit = useMemo(() => {
		const subscriptionProfit = statistics?.historic_subscription_personal_profit ? statistics.historic_subscription_personal_profit * 0.01 : 0;

		return subscriptionProfit;
	}, [statistics]);

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 md:grid-cols-2 lg:grid-cols-7">
			<div className="col-span-7 grid grid-cols-2 gap-4 overflow-y-auto xl:grid-cols-4">
				<Card
					title="Ganancia personal 2"
					amount={historic_subscription_profit + historic_membership_profit * percentage}
					tooltip="Ganancia total del asociado por pagos de sus referidos y suscripciones."
					loading={isStatisticsLoading}
				/>
				<Card
					title="Total bruto por referidos"
					amount={total_profit}
					tooltip="Ingresos totales obtenidos por referidos y suscripciones sin descontar fees de las tiendas."
					loading={isStatisticsLoading}
				/>
				<Card
					title="Número de referidos"
					amount={influencerUserCount}
					tooltip="Número de referidos que han comprado en los últimos 30 días."
					loading={isStatisticsLoading}
					isMoney={false}
				/>
			</div>
			<div className="col-span-7 rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Ganancias por compras de los referidos.</h3>
				</div>
				{isStatisticsLoading ? (
					<div className="flex h-[400px] items-center justify-center">
						<Spinner />
					</div>
				) : (
					<InfluencerBarChart chartRef={chartRef} dates={dates} showByMonths={showByMonths} percentage={percentage} />
				)}
			</div>
		</div>
	);
}

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartJSTooltip);

interface InfluencerBarChartProps {
	chartRef: RefObject<
		ChartJSOrUndefined<
			"bar",
			{
				date: string;
				value: number;
			}[]
		>
	>;
	dates: DateRange | undefined;
	showByMonths: boolean;
	percentage: number;
}

interface dataInterface {
	date: string;
	value: number;
}

export default function InfluencerBarChart({chartRef, dates, showByMonths, percentage}: InfluencerBarChartProps) {
	const {data: statistics} = useDashboardByNameQuery("influencerIncome");

	function groupAndSort(totalProfit: dataInterface[]): dataInterface[] {
		return totalProfit
			.reduce((acc: dataInterface[], curr) => {
				const existingDayData = acc.find(el => isSameDay(new Date(el.date), new Date(curr.date)));
				if (existingDayData) {
					existingDayData.value += curr.value;
				} else {
					acc.push(curr);
				}
				return acc;
			}, [])
			.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
	}

	function chartData(dataArray: dataInterface[], groupedArray: dataInterface[], showMonthly?: boolean) {
		return dataArray
			.map(fillEl => {
				const el = groupedArray.find(statEl => {
					return showMonthly
						? isSameMonth(new Date(fillEl.date), new Date(statEl.date))
						: isSameDay(new Date(fillEl.date), new Date(statEl.date));
				});

				if (el) return {...el, date: fillEl.date};

				return fillEl;
			})
			.reverse();
	}

	const data: ChartData<"bar", dataInterface[]> | null = useMemo(() => {
		const startDate = new Date(dates?.from ?? 0);
		const endDate = new Date(dates?.to ?? Date.now());
		const diff_days = differenceInDays(endDate, startDate) + 1;
		const diff_months = differenceInMonths(endDate, startDate) + 1;

		//Creates a template data to be filled by the statistics
		const daysArray = Array(diff_days)
			.fill(0)
			.map((_, idx) => ({date: addDays(endOfDay(endDate), -idx).toISOString(), value: 0}));

		const monthArray = Array(diff_months)
			.fill(0)
			.map((_, idx) => ({date: format(addDays(endOfDay(endDate), -idx * 30), "yyyy-MM-dd", {locale: es}), value: 0}));

		//Influencer Memberships Earnings
		const memberships =
			statistics?.membership_profit?.map(({date, profit, fee}) => ({
				date: new Date(date).toISOString(),
				value: profit * 0.01 - fee * 0.01,
			})) ?? [];

		const monthlyMemberships = chartData(monthArray, memberships, true);
		const dailyProfitData = chartData(daysArray, memberships);
		const membershipsData = showByMonths ? monthlyMemberships : dailyProfitData;

		//Platform Memberships Earnings
		const platformMembershipProfit =
			statistics?.membership_profit?.map(({date, profit, fee}) => ({
				date: new Date(date).toISOString(),
				value: (profit * 0.01 - fee * 0.01) * (1 - percentage),
			})) ?? [];

		const platformSubscriptionProfit =
			statistics?.subcription_profit?.map(({date, personal_profit, profit, fee}) => ({
				date: new Date(date).toISOString(),
				value: profit * 0.01 - fee * 0.01 - personal_profit * 0.01,
			})) ?? [];

		const totalProfit = [...platformMembershipProfit, ...platformSubscriptionProfit];

		const totalProfitGrouped = groupAndSort(totalProfit);
		const dailyPlatformProfit = chartData(daysArray, totalProfitGrouped);
		const monthlyPlatformProfit = chartData(monthArray, totalProfitGrouped, true);
		const platformProfit = showByMonths ? monthlyPlatformProfit : dailyPlatformProfit;

		//Fees
		const membershipFees =
			statistics?.membership_profit?.map(({date, fee}) => ({
				date: new Date(date).toISOString(),
				value: fee * 0.01,
			})) ?? [];

		const subscriptionFees =
			statistics?.subcription_profit?.map(({date, fee}) => ({
				date: new Date(date).toISOString(),
				value: fee * 0.01,
			})) ?? [];

		const totalFees = [...membershipFees, ...subscriptionFees];
		const totalFeesGrouped = groupAndSort(totalFees);

		const dailyFeesData = chartData(daysArray, totalFeesGrouped);
		const monthlyFees = chartData(monthArray, totalFeesGrouped, true);
		const fees = showByMonths ? monthlyFees : dailyFeesData;

		//Influencer Subscriptions Earnings
		const subscriptionProfit =
			statistics?.subcription_profit?.map(({date, personal_profit}) => ({
				date: new Date(date).toISOString(),
				value: personal_profit * 0.01,
			})) ?? [];

		const monthlySubscriptions = chartData(monthArray, subscriptionProfit, true);
		const dailySubscriptionsData = chartData(daysArray, subscriptionProfit);
		const subscriptionData = showByMonths ? monthlySubscriptions : dailySubscriptionsData;

		return {
			datasets: [
				{
					label: "Ganancias por Membresías",
					data: membershipsData.map(el => ({
						...el,
						value: el.value * percentage,
					})),
					backgroundColor: "#88a286",
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "value",
					},
				},
				{
					label: "Ganancias por Suscripciones",
					data: subscriptionData,
					backgroundColor: "#64007c",
					borderRadius: 4,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "value",
					},
				},
			],
		};
	}, [dates?.from, dates?.to, statistics?.membership_profit, statistics?.subcription_profit, showByMonths, percentage]);

	return (
		<div className="relative p-6 pt-0">
			<Bar
				height={350}
				ref={chartRef}
				options={{
					responsive: true,
					maintainAspectRatio: false,
					plugins: {
						tooltip: {
							callbacks: {
								title(tooltipItems) {
									const date = new Date(tooltipItems[0].label);
									if (showByMonths) {
										return format(date, "MMM - yyyy", {locale: es}).toLowerCase();
									}
									return format(date, "EE, dd MMMM", {locale: es}).toLowerCase();
								},
								label: function (context) {
									let label = context.dataset.label || "";

									if (label) {
										label += ": ";
									}
									if (context.parsed.y !== null) {
										label += new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(context.parsed.y);
									}
									return label;
								},
							},
						},
					},
					scales: {
						x: {
							ticks: {
								callback(tickValue) {
									const date = new Date(this.getLabelForValue(tickValue as any));

									const formattedDate = showByMonths
										? format(date, "MMM - yyyy", {locale: es}).toLowerCase()
										: format(date, "MMM d", {locale: es}).toLowerCase();

									return formattedDate;
								},
							},
							grid: {
								color: "transparent",
							},
							stacked: true,
						},
						y: {
							beginAtZero: true,
							stacked: true,
						},
					},
				}}
				data={data}
			/>
		</div>
	);
}
