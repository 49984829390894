interface LoadingDotsProps {
	color?: string;
}

const LoadingDots = ({color = "#000"}: LoadingDotsProps) => {
	return (
		<span className="loading">
			<span style={{backgroundColor: color}} />
			<span style={{backgroundColor: color}} />
			<span style={{backgroundColor: color}} />
		</span>
	);
};

export default LoadingDots;
