import {useMemo} from "react";
import {PlatformReport} from "../../../../_store/features/reports/types";
import {InformationCircle} from "../../../../components/primitives/icons/InformationCircle";

interface CostTableProps {
	list: PlatformReport["costs_list"];
}

export default function CostTable(props: CostTableProps) {
	const {list} = props;
	const amount_total = useMemo(() => list.reduce((acc, cost) => acc + cost.total, 0), [list]);

	if (list.length === 0) {
		return (
			<div className="flex flex-col items-center justify-center gap-2 rounded-sm border border-gray-200 py-10 md:col-span-2">
				<InformationCircle className="size-20" />
				<span className="text-lg text-gray-600">No hay gastos de la plataforma registrados en este periodo.</span>
			</div>
		);
	}

	return (
		<div>
			<div className="inline-block min-w-full py-2 align-middle">
				<div className="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
					<table className="min-w-full divide-y divide-gray-300 text-left">
						<colgroup>
							<col className="w-full sm:w-1/2" />
							<col className="sm:w-1/6" />
							<col className="sm:w-1/6" />
							<col className="sm:w-1/6" />
						</colgroup>
						<thead className="border-b border-gray-300 text-gray-900">
							<tr>
								<th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8">
									Concepto
								</th>
								<th scope="col" className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell">
									Fecha
								</th>
								<th scope="col" className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell">
									Cantidad
								</th>
								<th scope="col" className="px-3 py-3.5 pr-8 text-right text-sm font-semibold text-gray-900">
									Total
								</th>
							</tr>
						</thead>
						<tbody>
							{list.map((cost, index) => (
								<tr key={index} className="border-b border-gray-200 bg-white">
									<td className="max-w-0 py-2 pl-4 pr-8 text-sm sm:pl-6 lg:pl-8">
										<div className="font-medium text-gray-900">{cost.description || "--"}</div>
									</td>
									<td className="hidden px-3 py-2 text-right text-sm text-gray-500 sm:table-cell">
										{cost.transaction_date ? new Date(cost.transaction_date).toLocaleDateString() : "--"}
									</td>
									<td className="hidden px-3 py-2 text-right text-sm text-gray-500 sm:table-cell">{cost.units}</td>
									<td className="py-2 pl-3 pr-4 text-right text-sm text-gray-500">
										{Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(cost.total / 100)}
									</td>
								</tr>
							))}
						</tbody>
						<tfoot>
							<tr>
								<th
									scope="col"
									colSpan={3}
									className="hidden py-4 pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0"
								>
									Total
								</th>
								<th scope="row" className="py-4 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:hidden">
									Total
								</th>
								<td className="py-4 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 ">
									{Intl.NumberFormat("en-US", {style: "currency", currency: "USD"}).format(amount_total / 100)}
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</div>
	);
}
