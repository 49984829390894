import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {auth} from "../../../firebase";
import {
	CreateInfluencerReportBody,
	CreateInfluencerReportResult,
	GetInfluencerApiParams,
	GetInfluencerGenericParams,
	GetInfluencerReportParams,
	InfluencerMetadata,
	InfluencerPerformance,
	InfluencerRegistration,
	InfluencerRevenue,
	InfluencerTransitions,
	ReportResponse,
} from "./types";

const apiBaseUrl = process.env.REACT_APP_APP_DOMAIN;
const baseQuery = fetchBaseQuery({
	baseUrl: apiBaseUrl,
	async prepareHeaders(headers) {
		const token = await auth.currentUser?.getIdToken();
		if (token) {
			headers.set("Authorization", `Bearer ${token}`);
		}
		headers.set("Content-Type", "application/json");
		headers.set("Accept", "*/*");
		return headers;
	},
});

function generateDateHash(startDate: string, endDate: string = "none") {
	const data = `${startDate}|${endDate}`;
	let hash = 0;
	for (let i = 0; i < data.length; i++) {
		hash = (hash << 5) - hash + data.charCodeAt(i);
		hash |= 0;
	}
	return hash.toString(16);
}

export const influencerApi = createApi({
	reducerPath: "influencer",
	baseQuery,
	tagTypes: [
		"InfluencerPerformance",
		"InfluencerRevenue",
		"InfluencerRegistration",
		"InfluencerMetadata",
		"InfluencerTransitions",
		"InfluencerReport",
	],
	endpoints: builder => ({
		getInfluencerData: builder.query<InfluencerMetadata, Partial<GetInfluencerGenericParams>>({
			query: params => ({
				url: "/get_influencer_data",
				params,
			}),
			providesTags: ["InfluencerMetadata"],
		}),
		getInfluencerPerformance: builder.query<InfluencerPerformance, GetInfluencerApiParams>({
			query: params => ({
				url: "/get_influencer_performance",
				params,
			}),
			providesTags: (result, error, params) => [{type: "InfluencerPerformance", id: generateDateHash(params.initial_date, params.final_date)}],
		}),
		getInfluencerRevenue: builder.query<InfluencerRevenue, GetInfluencerApiParams>({
			query: params => ({
				url: "/get_influencer_revenue",
				params,
			}),
			providesTags: (result, error, params) => [{type: "InfluencerRevenue", id: generateDateHash(params.initial_date, params.final_date)}],
		}),
		getInfluencerRegistration: builder.query<InfluencerRegistration, GetInfluencerApiParams>({
			query: params => ({
				url: "/get_influencer_registration",
				params,
			}),
			providesTags: (result, error, params) => [{type: "InfluencerRegistration", id: generateDateHash(params.initial_date, params.final_date)}],
		}),
		getInfluencerTransitions: builder.query<InfluencerTransitions, GetInfluencerApiParams>({
			query: params => ({
				url: "/get_influencer_transitions",
				params,
			}),
			providesTags: (result, error, params) => [{type: "InfluencerTransitions", id: generateDateHash(params.initial_date, params.final_date)}],
		}),
		postCreateInfluencerReport: builder.mutation<CreateInfluencerReportResult, CreateInfluencerReportBody>({
			query: body => ({
				url: "/create_report_influencer",
				method: "POST",
				body,
			}),
			invalidatesTags: ["InfluencerReport"],
		}),
		getInfluencerReport: builder.query<ReportResponse, GetInfluencerReportParams>({
			query: params => ({
				url: "/get_report_influencer",
				params,
			}),
			providesTags: (result, error, params) => [{type: "InfluencerReport", id: generateDateHash(params.initial_date)}],
		}),
	}),
});

export const {
	useGetInfluencerTransitionsQuery,
	useGetInfluencerDataQuery,
	useGetInfluencerPerformanceQuery,
	useGetInfluencerRevenueQuery,
	useGetInfluencerRegistrationQuery,
	useGetInfluencerReportQuery,
	usePostCreateInfluencerReportMutation,
} = influencerApi;
