import {endOfDay, endOfToday, startOfMonth, startOfToday} from "date-fns";
import {useState} from "react";
import {DateRange} from "react-day-picker";

interface UseDatesProps {
	onDateRangeChange?: () => void | undefined;
}

export default function useDates({onDateRangeChange}: UseDatesProps) {
	const [dates, setSelectedDate] = useState<DateRange>(() => {
		const to = endOfToday();
		const from = startOfMonth(Date.now());
		return {
			from,
			to,
		};
	});

	const handleDateRangeChange = (s: number, e: number) => {
		const start = s * 1000;
		const end = e * 1000;

		const endOfDayEnd = endOfDay(new Date(end));

		setSelectedDate({from: new Date(start), to: endOfDayEnd});
		onDateRangeChange?.();
		// dispatch(dashboardPartnerActions.getPartnerGeneralIncomeAndProfit(selectGroupingRange(start, end)));
	};

	return {dates, handleDateRangeChange, setSelectedDate};
}
