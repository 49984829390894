import {useEffect, useState} from "react";
import {DatePickerWithRange} from "../../../components/blocks/DateRangePicker";
import {DateRange} from "react-day-picker";
import {endOfToday, formatISO9075, startOfDay, subMonths} from "date-fns";
import {useDashboardByNameQuery} from "../../../_store/features/dashboard/hooks";
import {useAppDispatch} from "../../../_store/hooks";
import {dashboardActions} from "../../../_store/features/dashboard/dashboard-slice";
import VerificationsChart from "./_subcomponents/VerificationsChart";
import ActiveAndInactiveUsers from "./_subcomponents/ActiveAndInactiveUsers";
import {ActiveInactiveChartDetail} from "./_subcomponents/ActiveInactiveChartDetail";
import UsersVerificationChart from "./_subcomponents/UsersVerificationChartDetail";
import {DailyDatum, GetActiveUsersResponse} from "../../../data-access/dashboard/types";
import UsersConversionChart from "./_subcomponents/UsersConversionChart";
import {usersConvertionActions} from "../../../_store/features/users-conversion/user-conversion-slice";
import {GROUPING} from "../../../constants";
import AppConsuptionWorldMap from "./_subcomponents/AppConsuptionWorldMap";
import {Button} from "../../../components/primitives/Button";

export default function UsersInfoStatistics() {
	const dispatch = useAppDispatch();
	const usersData = useDashboardByNameQuery("usersVerificationData");
	const usersDailyVerificationsData = useDashboardByNameQuery("usersDailyVerificationsData");
	const usersActiveData = useDashboardByNameQuery("usersActiveData");
	const [showByDates, setShowByDates] = useState(true);
	const [activeDetailChart, setActiveDetailChart] = useState<"verification" | "active" | null>("active");
	const [usersActivePieData, setUsersActivePieData] = useState<DailyDatum[] | undefined>();
	const [showGlobalMap, setShowGlobalMap] = useState(false);

	const [dates, setSelectedDate] = useState<DateRange>(() => {
		const to = endOfToday();
		const from = startOfDay(subMonths(to, 1));
		return {
			from,
			to,
		};
	});

	useEffect(() => {
		if (!usersData.data) {
			dispatch(dashboardActions.getUsersVerificationDataAction());
		}
		dispatch(
			dashboardActions.getUsersActiveDataAction({
				initial_date: showByDates ? formatISO9075(dates?.from!) : undefined,
				final_date: showByDates ? formatISO9075(dates?.to!) : undefined,
			}),
		).then(res => {
			if (res.meta.requestStatus !== "fulfilled" || !res.payload || showByDates) return;
			const info = res.payload as GetActiveUsersResponse;
			setUsersActivePieData(info.daily_data);
		});
		dispatch(
			dashboardActions.getUsersDailyVerificationDataAction({
				initial_date: showByDates ? formatISO9075(dates?.from!) : undefined,
				final_date: showByDates ? formatISO9075(dates?.to!) : undefined,
			}),
		);
		dispatch(
			usersConvertionActions.getUsersConversion({
				grouping: GROUPING.DAILY,
				initial_date: formatISO9075(dates?.from!),
				final_date: formatISO9075(dates?.to!),
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates]);

	const getGlobalMapConsumption = () => {
		setShowGlobalMap(true);
		dispatch(
			dashboardActions.getAppConsumptionByLocation({
				initial_date: showByDates ? formatISO9075(dates?.from!) : undefined,
				final_date: showByDates ? formatISO9075(dates?.to!) : undefined,
			}),
		);
	};

	const handleDateRangeChange = (s: number, e: number) => {
		setShowByDates(true);
		setSelectedDate({from: new Date(s * 1000), to: new Date(e * 1000)});
	};

	return (
		<div className="flex h-screen flex-col">
			<div className="flex items-center justify-between border-b border-b-border bg-background p-6">
				<h2 className="scroll-m-20 text-3xl font-extrabold tracking-tight">Información de Usuarios</h2>
				<DatePickerWithRange
					onDateRangeChange={handleDateRangeChange}
					date={dates}
					className="w-fit"
					disabled={{before: new Date("2023-10-01T00:00:00-05:00"), after: new Date()}}
				/>
			</div>
			<div className="flex w-full flex-col p-6">
				<UsersConversionChart />
				<div className="flex w-full flex-row justify-between gap-x-6 pt-2">
					<VerificationsChart
						statistics={usersData.data!}
						loading={usersData.isLoading}
						onClick={() => {
							setActiveDetailChart("verification");
						}}
					/>
					<ActiveAndInactiveUsers
						statistics={usersActivePieData as DailyDatum[]}
						loading={usersActiveData.isLoading}
						onClick={() => {
							setActiveDetailChart("active");
						}}
						totalActive={usersActiveData?.data?.total_created_users}
						totalInactive={usersActiveData?.data?.total_disabled_users}
					/>
				</div>
				{activeDetailChart === "verification" && (
					<UsersVerificationChart
						dates={dates}
						isLoading={usersDailyVerificationsData.isLoading}
						statistics={usersDailyVerificationsData.data}
					/>
				)}
				{activeDetailChart === "active" && <ActiveInactiveChartDetail dates={dates} statistics={usersActiveData.data} />}

				{showGlobalMap ? (
					<AppConsuptionWorldMap />
				) : (
					<Button variant={"outline"} className="m-auto w-fit" onClick={getGlobalMapConsumption}>
						Mostrar Mapa de Consumo
					</Button>
				)}
			</div>
		</div>
	);
}
