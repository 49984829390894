import {ChartOptions} from "chart.js";
import {useMemo} from "react";
import {Pie} from "react-chartjs-2";
import {DailyDatum} from "../../../../data-access/dashboard/types";
import {Spinner} from "../../../../components/primitives/icons";

interface VerificationsChartProps {
	statistics: DailyDatum[];
	loading?: boolean;
	onClick: () => void;
	totalActive?: number;
	totalInactive?: number;
}

const options: ChartOptions<"pie"> = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			position: "top" as const,
		},
	},
};

export default function ActiveAndInactiveUsers({statistics, loading, onClick, totalActive, totalInactive}: VerificationsChartProps) {
	const data = useMemo(() => {
		const sortedData = statistics ? statistics.slice().sort((a, b) => a.created - b.created) : [];
		const usersActive = sortedData.reduce((acc, curr) => acc + curr.created, 0);
		const usersInactive = sortedData.reduce((acc, curr) => acc + curr.disabled, 0);

		return {
			labels: ["Registrados", "Eliminados"],
			datasets: [
				{
					label: "Cantidad de usuarios",
					data: [totalActive ?? usersActive, totalInactive ?? usersInactive],
					backgroundColor: ["rgba(0, 172, 7, 1)", "rgba(242, 116, 116, 1)"],
					borderColor: "white",
					borderWidth: 1,
				},
			],
		};
	}, [statistics, totalActive, totalInactive, loading]);

	const handleOnClick = () => {
		onClick?.();
	};

	return (
		<div className="w-1/2 cursor-pointer rounded-md border" onClick={handleOnClick}>
			<h3 className="my-4 text-center text-xl font-semibold leading-none tracking-tight">Usuarios activos</h3>
			<div className="p-6 pt-0">
				{loading ? (
					<div className="flex w-full items-center justify-center">
						<Spinner />
					</div>
				) : (
					<Pie height={350} options={options} data={data} />
				)}
			</div>
		</div>
	);
}
