import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {auth} from "../../../firebase";
import {CreateReportApiBody, CreateReportApiResponse, GetReportApiParams, RemoveReportApi, ReportResponse} from "./types";

const apiBaseUrl = process.env.REACT_APP_APP_DOMAIN;
const baseQuery = fetchBaseQuery({
	baseUrl: apiBaseUrl,
	async prepareHeaders(headers) {
		const token = await auth.currentUser?.getIdToken();
		if (token) {
			headers.set("Authorization", `Bearer ${token}`);
		}
		headers.set("Content-Type", "application/json");
		headers.set("Accept", "*/*");
		return headers;
	},
});

function generateDateHash(startDate: string, endDate: string = "none") {
	const data = `${startDate}|${endDate}`;
	let hash = 0;
	for (let i = 0; i < data.length; i++) {
		hash = (hash << 5) - hash + data.charCodeAt(i);
		hash |= 0;
	}
	return hash.toString(16);
}

export const apiReports = createApi({
	baseQuery,
	reducerPath: "apiReports",
	tagTypes: ["Reports"],
	endpoints: builder => ({
		getReports: builder.query<ReportResponse, GetReportApiParams>({
			query: params => ({
				url: "/get_platform_report",
				params,
			}),
			providesTags: (result, error, params) => [{type: "Reports", id: generateDateHash(params.final_date + params.initial_date)}],
		}),
		postCreateReport: builder.mutation<CreateReportApiResponse, CreateReportApiBody>({
			query: body => ({
				url: "/create_report_platform",
				method: "POST",
				body,
			}),
			invalidatesTags: ["Reports"],
		}),
		deleteReport: builder.mutation<unknown, RemoveReportApi>({
			query: ({final_date, initial_date}) => ({
				url: `/remove_report_platform?initial_date=${initial_date}&final_date=${final_date}`,
				method: "DELETE"
			}),
			invalidatesTags: ["Reports"],
		})
	}),
});

export const {useGetReportsQuery, usePostCreateReportMutation, useDeleteReportMutation} = apiReports;
