import {Report} from "../../../../_store/features/influencer/types";
import {Card} from "../../../../components/blocks/Stats/Card";

interface IncomeInfluencerCardProps {
	report: Report;
	isLoading: boolean;
}

const IncomeInfluencerCard = ({report, isLoading}: IncomeInfluencerCardProps) => {
	return (
		<div className="grid grid-flow-row grid-cols-2 gap-3 xl:grid-cols-4 2xl:grid-cols-5">
			<Card
				title="Ganancias Personales"
				amount={(report.commission.influencer_commission_total + report.influencer_earn_total) / 100}
				tooltip="Estimación de ganancias del periodo basado en las suscripciones de tus referidos y las visualizaciones de su contenido."
				loading={isLoading}
			/>
			<Card
				title="Ingresos por Referidos"
				amount={report.commission.influencer_commission_total / 100}
				tooltip="Estimación del total generado por las compras de suscripciones realizadas por tus referidos en el periodo seleccionado."
				loading={isLoading}
			/>
			<Card
				title="Ingresos por Visualizaciones"
				amount={report.influencer_earn_total / 100}
				tooltip="Estimación del total generado por las visualizaciones de contenidos de tus series relacionadas."
				loading={isLoading}
			/>
			<Card
				title="Usuarios referidos"
				tooltip="Usuarios registrados en el período seleccionado."
				loading={isLoading}
				amount={report.registrations.total}
				isMoney={false}
			/>
		</div>
	);
};

export default IncomeInfluencerCard;
