import {useEffect, useMemo, useState} from "react";
import {Line} from "react-chartjs-2";
import {DateRange} from "react-day-picker";
import {enUS} from "date-fns/locale";
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend} from "chart.js";
import {addDays, differenceInDays, differenceInMonths, endOfDay, format, formatISO9075, isSameDay, isSameMonth} from "date-fns";
import {oneMonth} from "../../../../../../utils/barGraphTransformData";
import {useAppDispatch} from "../../../../../_store/hooks";
import {dashboardCorporateActions} from "../../../../../_store/features/dashboard-corporate/dashboard-corporate-slice";
import {Spinner} from "../../../../../components/primitives/icons";
import {useDashboardCorporateByNameQuery} from "../../../../../_store/features/dashboard-corporate/hooks";

interface dataInterface {
	date: string;
	slotsVariation: number;
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function SubscriptionSlotsVariation({dates}: {dates: DateRange | undefined}) {
	const dispatch = useAppDispatch();
	const {isLoading, data: statistics} = useDashboardCorporateByNameQuery("getSubscriptionSlotsVariations");
	const [showByMonths, setShowByMonths] = useState(false);
	const startDate = new Date(dates?.from ?? 0);
	const endDate = new Date(dates?.to ?? Date.now());
	const diff_days = differenceInDays(endDate, startDate) + 1;
	const diff_months = differenceInMonths(endDate, startDate) + 1;

	useEffect(() => {
		dispatch(
			dashboardCorporateActions.getSubscriptionSlotsVariations({
				initial_date: formatISO9075(dates?.from!),
				final_date: formatISO9075(dates?.to!),
			}),
		);
		const duration = dates ? dates?.to!.getTime() - dates?.from!.getTime() : 0;
		setShowByMonths(duration > oneMonth);
	}, [dates, dispatch]);

	function chartData(dataArray: dataInterface[], groupedArray: dataInterface[], showMonthly?: boolean) {
		return dataArray
			.map(fillEl => {
				const el = groupedArray.find(statEl => {
					return showMonthly
						? isSameMonth(new Date(fillEl.date), new Date(statEl.date))
						: isSameDay(new Date(fillEl.date), new Date(statEl.date));
				});
				if (el) return {...el, date: fillEl.date};
				return fillEl;
			})
			.reverse();
	}

	const data = useMemo(
		() => {
			const monthArray = Array(diff_months)
				.fill(0)
				.map((_, idx) => ({date: format(addDays(endOfDay(endDate), -idx * 31), "yyyy-MM-dd", {locale: enUS}), slotsVariation: 0}));

			const daysArray = Array(diff_days)
				.fill(0)
				.map((_, idx) => ({date: addDays(endOfDay(endDate), -idx).toISOString(), slotsVariation: 0}));

			const slotsIncrement =
				statistics?.daily?.map(item => {
					const itemDate = new Date(item.year, item.month - 1, item.day);
					return {date: itemDate.toDateString(), slotsVariation: item.increased};
				}) ?? [];

			const slotsDecrement =
				statistics?.daily?.map(item => {
					const itemDate = new Date(item.year, item.month - 1, item.day);
					return {date: itemDate.toDateString(), slotsVariation: Math.abs(item.decreased)};
				}) ?? [];

			let slotsIncrementData: dataInterface[] = [];
			let slotsDecrementData: dataInterface[] = [];

			if (showByMonths) {
				const slotsIncrementsMonthly = monthArray.map(month => {
					const monthDataIncrement = slotsIncrement.filter(item => isSameMonth(new Date(item.date), new Date(month.date)));
					const groupedSlotsIncrement = monthDataIncrement.reduce((acc, curr) => acc + curr.slotsVariation, 0);
					return {date: month.date, slotsVariation: groupedSlotsIncrement};
				});
				const slotsDecrementsMonthly = monthArray.map((month, idx) => {
					const monthDataDecrement = slotsDecrement.filter(item => isSameMonth(new Date(item.date), new Date(month.date)));
					const groupedSlotsDecrement = monthDataDecrement.reduce((acc, curr) => acc + curr.slotsVariation, 0);
					return {date: month.date, slotsVariation: groupedSlotsDecrement};
				});
				// MonthlyData
				slotsIncrementData = chartData(monthArray, slotsIncrementsMonthly, true);
				slotsDecrementData = chartData(monthArray, slotsDecrementsMonthly, true);
			} else {
				// DailyData
				slotsIncrementData = chartData(daysArray, slotsIncrement);
				slotsDecrementData = chartData(daysArray, slotsDecrement);
			}

			return {
				datasets: [
					{
						label: "Cupos adquiridos",
						data: slotsIncrementData,
						borderColor: "rgba(116, 116, 242, 1)",
						parsing: {
							xAxisKey: "date",
							yAxisKey: "slotsVariation",
						},
					},
					{
						label: "Cupos abandonados",
						data: slotsDecrementData,
						borderColor: "rgba(242, 116, 116, 1)",
						parsing: {
							xAxisKey: "date",
							yAxisKey: "slotsVariation",
						},
					},
				],
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[statistics, dates, showByMonths],
	);

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 pt-6 md:grid-cols-2 lg:grid-cols-7">
			<div className="col-span-5 rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Variación de los cupos del Plan de amigos</h3>
				</div>
				{isLoading ? (
					<div className="flex h-[350px] items-center justify-center">
						<Spinner />
					</div>
				) : (
					<div className="flex flex-col px-6 pb-6">
						<div className="flex w-full pt-0">
							<Line
								height={350}
								id="slots-variation-chart"
								options={{
									responsive: true,
									maintainAspectRatio: false,
									plugins: {
										tooltip: {
											callbacks: {
												title(tooltipItems) {
													const date = new Date(tooltipItems[0].label);
													if (showByMonths) {
														return format(date, "MMM - yyyy", {locale: enUS}).toLowerCase();
													}
													return format(date, "EE, dd MMMM", {locale: enUS}).toLowerCase();
												},
											},
										},
									},
									scales: {
										x: {
											ticks: {
												callback(tickslotsIncrement) {
													const date = new Date(this.getLabelForValue(tickslotsIncrement as any));

													const formattedDate = showByMonths
														? format(date, "MMM - yyyy", {locale: enUS}).toLowerCase()
														: format(date, "MMM d", {locale: enUS}).toLowerCase();

													return formattedDate;
												},
											},
											grid: {
												display: false,
											},
										},
										y: {
											beginAtZero: true,
											grace: "10%",
										},
									},
								}}
								data={data}
							/>
						</div>
					</div>
				)}
			</div>
			<div className="col-span-2 flex h-fit flex-col items-start justify-start gap-6 rounded-lg border p-4">
				<h3 className="text-center text-2xl font-semibold">Variación de los Cupos</h3>
				<div className="flex w-full flex-col justify-start gap-3">
					<div className="flex justify-between gap-10 border-b">
						<p className="w-3/6 text-lg">Cupos Adquiridos</p>
						<p className="text-start text-lg">{statistics?.total_slots_increased}</p>
					</div>
					<div className="flex justify-between gap-10 border-b">
						<p className="w-fit text-lg">Cupos abandonados</p>
						<p className="text-start text-lg">{statistics?.total_slots_decreased ?? 0}</p>
					</div>
					<div className="flex justify-between gap-10 border-b">
						<p className="w-fit text-lg">Variación del período</p>
						<p className="text-start text-lg">{statistics?.total_slots}</p>
					</div>
				</div>
				<div className="mt-3 flex w-full justify-between gap-10">
					<p className="w-fit text-2xl font-semibold">Ganancia Total:</p>
					<p className="text-lg">$ {((statistics?.slots_earnings ?? 0) / 100).toFixed(2)}</p>
				</div>
			</div>
		</div>
	);
}
