import {ChartData, ChartOptions} from "chart.js";
import {useMemo, useRef} from "react";
import {DateRange} from "react-day-picker";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {Bar} from "react-chartjs-2";
import {BarElement, CategoryScale, Chart as ChartJS, Tooltip as ChartJSTooltip, LinearScale} from "chart.js";
import {format, formatISO9075} from "date-fns";
import {es} from "date-fns/locale";
import {useGetInfluencerRegistrationQuery} from "../../../../../_store/features/influencer/api";
import {Spinner} from "../../../../../components/primitives/icons";

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartJSTooltip);

interface InfluencerBarChartProps {
	dates: DateRange;
}

interface Point {
	date: string;
	value: number;
}

const ReferralRegistrationLineChart = ({dates}: InfluencerBarChartProps) => {
	const chartRef = useRef<ChartJSOrUndefined<"bar", Point[]>>(null);

	const {data: influencerRegistration, isLoading} = useGetInfluencerRegistrationQuery({
		final_date: formatISO9075(dates.to!),
		initial_date: formatISO9075(dates.from!),
	});

	const data: ChartData<"bar", Point[]> | null = useMemo(() => {
		if (!dates?.from || !dates?.to) return {datasets: []};

		const rangeLenght = Math.ceil((new Date(dates.to).getTime() - new Date(dates.from).getTime()) / 1000 / 86400);

		const selectedData: Point[] = Array.from({length: rangeLenght}, (_, i) => {
			var date = new Date(dates.from!);
			date.setDate(date.getDate() + i);
			const formattedDate = formatISO9075(date, {representation: "date"});

			return {date: formattedDate, value: 0, total: 0};
		});

		for (const registration of influencerRegistration ?? []) {
			const index = selectedData.findIndex(data => data.date === registration.date);

			if (index !== -1) {
				selectedData[index].value += Number(registration.total);
			}
		}

		return {
			labels: selectedData.map(data => data.date),
			datasets: [
				{
					label: "Registro de referidos",
					data: selectedData,
					fill: false,
					backgroundColor: "#44aaaa",
					borderColor: "#44aaaa",
					borderWidth: 1,
					parsing: {
						xAxisKey: "date",
						yAxisKey: "value",
					},
				},
			],
		};
	}, [dates.from, dates.to, influencerRegistration]);

	return (
		<div className="grid grid-rows-[auto,min-content] gap-4 pt-6 md:grid-cols-2 lg:grid-cols-7">
			<div className="relative col-span-7 rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Registro de referidos</h3>
				</div>
				{isLoading ? (
					<div className="flex h-[350px] items-center justify-center">
						<Spinner />
					</div>
				) : (
					<div className="relative p-6 pt-0">
						<Bar
							height={350}
							ref={chartRef}
							data={data}
							options={{
								...options,
								scales: {
									x: {
										ticks: {
											callback(tickValue) {
												let value = this.getLabelForValue(tickValue as any);
												const date: Date = new Date(`${value}T00:00:00`);
												let formattedDate: string;

												formattedDate = format(date, "MMM d", {locale: es}).toLowerCase();

												return formattedDate;
											},
										},
										grid: {
											color: "transparent",
										},
										stacked: true,
									},
									y: {
										ticks: {
											precision: 1,
										},
										stacked: false,
									},
								},
							}}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default ReferralRegistrationLineChart;

const options: ChartOptions<"bar"> = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		tooltip: {
			callbacks: {
				title(tooltipItems) {
					return tooltipItems[0].label;
				},
				label(context) {
					const item = context.dataset.data[context.dataIndex] as unknown as Point;

					if (!item) return "";

					return "Registrados: " + item.value;
				},
			},
		},
	},
	scales: {
		x: {
			ticks: {
				callback(tickValue) {
					return format(new Date(this.getLabelForValue(tickValue as any)), "MMM d", {locale: es}).toLowerCase();
				},
			},
			grid: {
				color: "transparent",
			},
		},
	},
};
