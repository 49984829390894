import {DateRange} from "react-day-picker";
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend} from "chart.js";
import {Spinner} from "../../../../components/primitives/icons";
import {GetDailyVerificationsDataResponse} from "../../../../data-access/dashboard/types";
import {useEffect, useMemo, useState} from "react";
import {Line} from "react-chartjs-2";
import {oneMonth} from "../../../../../utils/barGraphTransformData";
import {addDays, differenceInDays, differenceInMonths, endOfDay, format, isSameDay, isSameMonth} from "date-fns";
import {enUS} from "date-fns/locale";

interface UsersVerificationChartDetailProps {
	statistics?: GetDailyVerificationsDataResponse | undefined;
	dates?: DateRange;
	isLoading?: boolean;
}

interface dataInterface {
	date: string;
	value: number;
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function UsersVerificationChartDetail({isLoading, dates, statistics}: UsersVerificationChartDetailProps) {
	const [showByMonths, setShowByMonths] = useState(false);
	const startDate = new Date(dates?.from ?? 0);
	const endDate = new Date(dates?.to ?? Date.now());
	const diff_days = differenceInDays(endDate, startDate) + 1;
	const diff_months = differenceInMonths(endDate, startDate) + 1;

	useEffect(() => {
		const duration = dates ? dates?.to!.getTime() - dates?.from!.getTime() : 0;
		setShowByMonths(duration > oneMonth);
	}, [dates]);

	function chartData(dataArray: dataInterface[], groupedArray: dataInterface[], showMonthly?: boolean) {
		return dataArray
			.map(fillEl => {
				const el = groupedArray.find(statEl => {
					return showMonthly
						? isSameMonth(new Date(fillEl.date), new Date(statEl.date))
						: isSameDay(new Date(fillEl.date), new Date(statEl.date));
				});

				if (el) return {...el, date: fillEl.date};

				return fillEl;
			})
			.reverse();
	}

	const data = useMemo(
		() => {
			const monthArray = Array(diff_months)
				.fill(0)
				.map((_, idx) => ({date: format(addDays(endOfDay(endDate), -idx * 31), "yyyy-MM-dd", {locale: enUS}), value: 0}));

			const daysArray = Array(diff_days)
				.fill(0)
				.map((_, idx) => ({date: addDays(endOfDay(endDate), -idx).toISOString(), value: 0}));

			const verifiedEmail = statistics?.daily_data?.map(item => ({date: item.date, value: item.type_1})) ?? [];
			const verifiedPhone = statistics?.daily_data?.map(item => ({date: item.date, value: item.type_2})) ?? [];
			const verifiedBoth = statistics?.daily_data?.map(item => ({date: item.date, value: item.type_3})) ?? [];
			const notVerified = statistics?.daily_data?.map(item => ({date: item.date, value: item.type_4})) ?? [];

			const monthlyVerifiedEmail = chartData(monthArray, verifiedEmail, true);
			const monthlyVerifiedPhone = chartData(monthArray, verifiedPhone, true);
			const monthlyVerifiedBoth = chartData(monthArray, verifiedBoth, true);
			const monthlyNotVerified = chartData(monthArray, notVerified, true);

			const dailyVerifiedEmail = chartData(daysArray, verifiedEmail);
			const dailyVerifiedPhone = chartData(daysArray, verifiedPhone);
			const dailyVerifiedBoth = chartData(daysArray, verifiedBoth);
			const dailyNotVerified = chartData(daysArray, notVerified);

			const verifiedEmailData = showByMonths ? monthlyVerifiedEmail : dailyVerifiedEmail;
			const verifiedPhoneData = showByMonths ? monthlyVerifiedPhone : dailyVerifiedPhone;
			const verifiedBothData = showByMonths ? monthlyVerifiedBoth : dailyVerifiedBoth;
			const notVerifiedData = showByMonths ? monthlyNotVerified : dailyNotVerified;

			return {
				datasets: [
					{
						label: "Correo verificado",
						data: verifiedEmailData,
						backgroundColor: "rgba(0, 172, 7, 1)",
						borderColor: "rgba(0, 172, 7, 1)",
						parsing: {
							xAxisKey: "date",
							yAxisKey: "value",
						},
					},
					{
						label: "Teléfono verificado",
						data: verifiedPhoneData,
						backgroundColor: "rgba(242, 116, 116, 1)",
						borderColor: "rgba(242, 116, 116, 1)",
						parsing: {
							xAxisKey: "date",
							yAxisKey: "value",
						},
					},
					{
						label: "Ambos",
						data: verifiedBothData,
						backgroundColor: "rgba(0, 80, 150, 1)",
						borderColor: "rgba(0, 80, 150, 1)",
						parsing: {
							xAxisKey: "date",
							yAxisKey: "value",
						},
					},
					{
						label: "Usuarios sin verificar",
						data: notVerifiedData,
						backgroundColor: "rgba(18, 18, 18, 0.31)",
						borderColor: "rgba(18, 18, 18, 0.31)",
						parsing: {
							xAxisKey: "date",
							yAxisKey: "value",
						},
					},
				],
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[statistics?.daily_data, dates, showByMonths],
	);

	return (
		<div className="flex w-full py-6">
			<div className="flex w-full flex-col rounded-lg border bg-card text-card-foreground shadow-sm">
				<div className="flex flex-col space-y-1.5 p-6">
					<h3 className="text-lg font-semibold leading-none tracking-tight">Usuarios verificados</h3>
				</div>
				{isLoading ? (
					<div className="flex h-[350px] items-center justify-center">
						<Spinner />
					</div>
				) : (
					<div className="flex w-full p-6 pt-0">
						<Line
							height={350}
							id="users-verifications"
							options={{
								responsive: true,
								maintainAspectRatio: false,
								plugins: {
									tooltip: {
										callbacks: {
											title(tooltipItems) {
												const date = new Date(tooltipItems[0].label);
												if (showByMonths) {
													return format(date, "MMM - yyyy", {locale: enUS}).toLowerCase();
												}
												return format(date, "EE, dd MMMM", {locale: enUS}).toLowerCase();
											},
										},
									},
								},
								scales: {
									x: {
										ticks: {
											callback(tickValue) {
												const date = new Date(this.getLabelForValue(tickValue as any));

												const formattedDate = showByMonths
													? format(date, "MMM - yyyy", {locale: enUS}).toLowerCase()
													: format(date, "MMM d", {locale: enUS}).toLowerCase();

												return formattedDate;
											},
										},
										grid: {
											color: "transparent",
										},
									},
									y: {
										beginAtZero: true,
										stacked: true,
									},
								},
							}}
							data={data}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
