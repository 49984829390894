import {DateRange} from "react-day-picker";
import {Card} from "../../../../../components/blocks/Stats/Card";
import {
	useGetInfluencerRegistrationQuery,
	useGetInfluencerRevenueQuery,
} from "../../../../../_store/features/influencer/api";
import {formatISO9075} from "date-fns";

interface IncomeInfluencerCardProps {
	dates: DateRange;
}

const IncomeInfluencerCard = ({dates}: IncomeInfluencerCardProps) => {
	const {data: influencerRevenue, isLoading: isLoadingRevenue} = useGetInfluencerRevenueQuery({
		final_date: formatISO9075(dates.to!),
		initial_date: formatISO9075(dates.from!),
	});
	const {data: influencerRegistration} = useGetInfluencerRegistrationQuery({
		final_date: formatISO9075(dates.to!),
		initial_date: formatISO9075(dates.from!),
	});

	const isLoading = isLoadingRevenue;

	const earningSubscriptions = (influencerRevenue?.total_influencer_revenue_annual || 0) + (influencerRevenue?.total_influencer_revenue_month || 0);

	const totalRegistrations =
		influencerRegistration?.reduce((acc, curr) => {
			acc += Number(curr.total);
			return acc;
		}, 0) ?? 0;

	const totalSubscriptions = influencerRevenue?.details?.reduce((acc, curr) => {
		acc += Number(curr.total);
		return acc;
	}, 0) ?? 0;

	return (
		<div className="grid grid-flow-row grid-cols-2 gap-3 xl:grid-cols-4 2xl:grid-cols-5">			
			<Card
				title="Ingresos por Referidos"
				amount={earningSubscriptions / 100}
				tooltip="Estimación del total generado por las compras de suscripciones realizadas por tus referidos en el periodo seleccionado."
				loading={isLoading}
			/>
			<Card
				title="Suscripciones"
				amount={totalSubscriptions}
				tooltip="Total de suscripciones realizadas por tus referidos en el periodo seleccionado."
				loading={isLoading}
				isMoney={false}
			/>
			<Card
				title="Usuarios referidos"
				tooltip="Usuarios registrados en el período seleccionado."
				loading={isLoading}
				amount={totalRegistrations}
				isMoney={false}
			/>
		</div>
	);
};

export default IncomeInfluencerCard;
