import {useMemo, useState} from "react";
import SelectMonth from "../../influencer-pages/reports/components/select-month";
import {useGetReportsQuery} from "../../../_store/features/reports/api";
import Report from "./components/report";

const currentDate = new Date();
const month = currentDate.getMonth();
const year = currentDate.getFullYear();

export function Reports() {
	const [initialDate, setQueryDate] = useState<string>(`${year}-${(month + 1).toString().padStart(2, "0")}-01`);
	const finalDate = useMemo(() => {
		const [y, m] = initialDate.split("-");
		const lastDay = new Date(Number(y), Number(m), 0).getDate();
		return `${y}-${m}-${lastDay}`;
	}, [initialDate]);

	const {data, isLoading, isFetching, isError} = useGetReportsQuery({
		initial_date: initialDate,
		final_date: finalDate,
	});

	return (
		<>
			<section className="sticky top-0 z-10 flex items-center justify-between border-b border-b-border bg-background p-6 py-4">
				<h2 className="flex-1 scroll-m-20 text-3xl font-extrabold tracking-tight">Reportes</h2>
				<div className="flex items-center gap-2">
					<SelectMonth onChangeMonth={setQueryDate} />
				</div>
			</section>

			<Report data={data} isError={isError} isLoading={isLoading || isFetching} initialDate={initialDate} finalDate={finalDate} />
		</>
	);
}
