import {formatInTimeZone} from "date-fns-tz";
import {ReportResponse} from "../../../../_store/features/influencer/types";
import {InformationCircle} from "../../../../components/primitives/icons/InformationCircle";
import {es} from "date-fns/locale";
import GenerateReportButton from "./generate-report-button";
import IncomeInfluencerCard from "./income-influencer-card";
import ReferralSubscriptionBarChart from "./referral-subscription--bar-chart";
import ReferralRegistrationLineChart from "./referral-registration-line-chart";
import ReferralPerformanceLineChart from "./referral-performance--line-chart";
import Details from "./details";

interface ReportProps {
	data: ReportResponse | undefined;
	isCorporate: boolean;
	influencer: string;
	isError: boolean;
	isLoading: boolean;
	queryDate: string;
}

function capitalize(s: string) {
	if (typeof s !== "string") return "";
	return s.charAt(0).toUpperCase() + s.slice(1);
}

const Report = (props: ReportProps) => {
	const {data, isError, isLoading, influencer, isCorporate, queryDate} = props;
	if (!data || isError) return <NoReportAvailable {...props} />;

	const {report, report_date} = data;

	return (
		<section className="flex flex-col space-y-4 p-6 py-4">
			<div className="flex items-center justify-between">
				<h1 className="text-sm font-light tracking-tight">
					Reporte {capitalize(formatInTimeZone(new Date(report_date), "UTC", "MMMM yyyy", {locale: es}))} (
					<span>{report.referral_code}</span>)
				</h1>
				<GenerateReportButton isCorporate={isCorporate} influencer={influencer} force queryDate={queryDate} />
			</div>

			<IncomeInfluencerCard report={report} isLoading={isLoading} />
			<ReferralSubscriptionBarChart report={report} report_date={report_date} isLoading={isLoading} />
			<ReferralPerformanceLineChart report={report} report_date={report_date} isLoading={isLoading} />
			<ReferralRegistrationLineChart report={report} report_date={report_date} isLoading={isLoading} />

			<Details isLoading={isLoading} report={report} />
		</section>
	);
};

interface NoReportAvailableProps {
	isCorporate: boolean;
	influencer: string;
	queryDate: string;
}

const NoReportAvailable = ({influencer, isCorporate, queryDate}: NoReportAvailableProps) => {
	const text = isCorporate
		? `No se ha generado un reporte aún`
		: `Por favor contacta con un administrador o espera a ser añadido el reporte solicitado.`;

	return (
		<div className="mx-auto my-10 flex h-full max-w-lg flex-col items-center justify-center text-center">
			<p className="mb-4 rounded-full p-3 text-sm font-medium text-secondary">
				<InformationCircle className="h-10 w-10 text-black" />
			</p>
			<h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-4xl">Reporte no disponible aún.</h1>
			<p className="mb-4 leading-7 [&:not(:first-child)]:mt-6">{text}</p>

			<GenerateReportButton isCorporate={isCorporate} influencer={influencer} queryDate={queryDate} />
		</div>
	);
};

export default Report;
