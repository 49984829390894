import {PropsWithChildren, useEffect, useRef} from "react";
import {Close} from "../icons";
import ClientOnlyPortal from "../Portal";
import {cn} from "../../../../utils/classNames";

interface ModalProps extends PropsWithChildren {
	title?: string;
	open: boolean;
	onDismiss: () => void;
	big?: boolean;
	className?: string;
}
export function Modal(props: ModalProps) {
	const {children, open, title, big, onDismiss, className} = props;
	const oldStyle = useRef<string>();

	useEffect(() => {
		oldStyle.current = document.body.style.overflow;
	}, []);

	useEffect(() => {
		const keyboardHandler = (e: KeyboardEvent) => {
			if (e.code === "Escape") {
				onDismiss?.();
			}
		};

		if (open) {
			window.addEventListener("keyup", keyboardHandler);
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = oldStyle.current as string;
		}

		return () => {
			window.removeEventListener("keyup", keyboardHandler);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	return open ? (
		<ClientOnlyPortal>
			<div role="dialog" className={cn("fixed bottom-0 left-0 right-0 top-0 z-50 bg-black bg-opacity-80", className)} aria-modal={true}>
				<div role="none" className="absolute bottom-0 left-0 right-0 top-0 backdrop-blur" onClick={onDismiss} />
				<div
					className={`relative top-1/2 mx-auto my-auto flex h-full -translate-y-1/2 flex-col rounded bg-neutral-900 px-6 sm:h-fit sm:max-h-[80%] sm:w-fit sm:max-w-[600px] sm:p-6 sm:ring-2 sm:ring-white ${
						big ? "sm:max-w-[80%]" : "sm:max-w-[600px]"
					}`}
				>
					<div className="flex justify-between">
						{title && <div className="my-3 text-2xl font-bold uppercase text-white">{title}</div>}
						<button className="text-white" onClick={onDismiss}>
							<Close strokeWidth={2.5} />
						</button>
					</div>
					<div className="overflow-y-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">{children}</div>
				</div>
			</div>
		</ClientOnlyPortal>
	) : null;
}
