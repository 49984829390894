import {RootState} from "../../../store";
import {useAppSelector} from "../../../hooks";
import {UsersConversionQueries, DataByName} from "../user-conversion-slice";

interface useUsersConversionInterface<T extends UsersConversionQueries> {
	data?: DataByName[T];
	isUninitialized: boolean;
	isLoading: boolean;
	isError: boolean;
	isSuccess: boolean;
}

export function useUsersConversionByNameQuery<T extends UsersConversionQueries>(name: T): useUsersConversionInterface<T> {
	const status = useAppSelector((state: RootState) => state.userConversion.statusByName[name]);
	const data = useAppSelector((state: RootState) => state.userConversion.dataByName[name]);

	const isUninitialized = status === undefined;
	const isLoading = status === "pending" || status === undefined;
	const isError = status === "rejected";
	const isSuccess = status === "fulfilled";

	return {data, isUninitialized, isLoading, isError, isSuccess};
}
