import toast from "react-hot-toast";
import {Button} from "../../../../../components/primitives/Button";
import {Chain} from "../../../../../components/primitives/icons";
import {useCallback} from "react";

interface ShareLinkButtonProps {
	link: string;
}

export default function ShareLinkButton({link}: ShareLinkButtonProps) {
	
	const handleCopyLink = useCallback(async () => {
		try {
			await navigator.clipboard.writeText(link);
			toast.success("Texto copiado al portapapeles.");
		} catch (error) {
			toast.error("Problema al copiar el texto.");
		}
	}, [link]);

	return (
		<Button className="flex gap-2" onClick={handleCopyLink}>
			Copiar Link <Chain className="size-3" />
		</Button>
	);
}
